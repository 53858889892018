import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyCFY_r6Csw1EVcJ0X7q0zyhn9HO6-MILSw",
  authDomain: "workwise-b84c6.firebaseapp.com",
  projectId: "workwise-b84c6",
  storageBucket: "workwise-b84c6.appspot.com",
  messagingSenderId: "818226093912",
  appId: "1:818226093912:web:a805cd572cdf8a7480cf2d",
  measurementId: "G-9Z581EP55S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging(app);

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
     // console.log('Registration successful, scope is:', registration.scope);
    })
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}

export const requestPermission = async () => {
    try {
      const token = await getToken(messaging, { vapidKey: 'BNTM5u2yhbNnIid_71txfWhc-qPUOsuUIYDwNjp6CS5wt3AjwA1Bce-j7kLyk9NFVFns6hK6lWjLRmWBOqJ5Fqg' });
      sendTokenToBackend(token);
      return true;
    } catch (error) {
      console.error('Error getting permission or token:', error);
    }
  };


onMessage(messaging, (payload) => {
   // console.log('Message received. ', payload);
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon || ''
    };
  
    new Notification(notificationTitle, notificationOptions);
  });

export const sendTokenToBackend = async (token_) => {
  try {

    var token = window.localStorage.getItem("token");
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    
    const response = await axios.post('store-fcm-token', {
      token: token_
    });
  
  } catch (error) {
    console.error('Error sending token to backend:', error);
  }
};

export { app, analytics, messaging };
