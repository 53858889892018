<template>
  <!-- Add Designation Modal -->
  <div id="add_designation" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Designation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="adddesgclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Designation Name <span class="text-danger">*</span></label
              >
             <!-- <input class="form-control" type="text" /> -->
			  
			  <Field
                    name="designation_name"
                    type="text"
                    class="form-control"
					v-model="form.designation_name"
					:class="{ 'is-invalid': errors.designation_name }"
                  />
                  <div class="invalid-feedback">{{ errors.designation_name }}</div>
                  <div class="designation_nameshow text-danger" id="designation_name"></div>
				  
            </div>
            <div class="input-block mb-3">
              <div class="form-check" style="padding-left:0px">
                <label class="form-check-label" for="customCheck2" style="margin-left:5px"
                  >Is Reporter ? </label
                >
                <input
                  name="is_reporter"
                  type="checkbox"
                  class="days recurring"
                  id="customCheck2"
                  :value="1"
                  v-model="form.is_reporter"
                  :checked="form.is_reporter"
                  style="margin-left: 10px"
                />
                <div class="invalid-feedback">{{ errors.is_reporter }}</div>
                <div class="is_reportershow text-danger" id="is_reporter"></div>
              </div>
            </div>
			<!--
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Department <span class="text-danger">*</span></label
              >
			  <Field
                    name="department"
                    as="select"
                    class="form-control"
					v-model="form.department"
					:class="{ 'is-invalid': errors.department }"
                  >
				  <option value="">Select Department</option>
				  <option v-for="department in departmentlist" :key="department.id" :value="department.id">{{ department.name }}</option>
				  <div class="invalid-feedback">{{ errors.department }}</div>
                  <div class="departmentshow text-danger" id="department"></div>
			  </Field>
              
            </div> -->
            <div class="">
              <table class="table table-striped custom-table">
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <!-- <th class="text-center">Show Navbar</th> -->
                    <th class="text-center">Read</th>
                    <th class="text-center">Write</th>
                    <th class="text-center">Create</th>
                    <th class="text-center">Delete</th>
                    <th class="text-center">Import</th>
                    <th class="text-center">Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Holidays</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.write"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.create"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.delete"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.import"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.export"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Employee</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.employee.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.write"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.create"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.delete"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.import"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.export"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Attendance</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox"
                        name="rememberme"
                        class="rememberme" v-model="permissions.attendance.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox"
                        name="rememberme"
                        class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.write"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.create"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.delete"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.import"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.export"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Department</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.department.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.write"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.create"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.delete"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.import"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.export"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.designation.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.write"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.create"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.delete"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.import"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.export"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Wages</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.wages.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.write"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.create"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.delete"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.import"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.export"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Schedule</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.write"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.create"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.delete"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.import"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.export"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Shift</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.shift.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.write"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.create"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.delete"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.import"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.export"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Leaves</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.leave.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.write"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.create"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.delete"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.import"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.export"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Clients</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.client.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.write"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.create"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.delete"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.import"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.export"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Projects</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.project.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.write"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.create"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.delete"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.import"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.export"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Tasks</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.task.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.write"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.create"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.delete"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.import"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.export"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Chats</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.chat.show_navbar" />
                        <span class="checkmark"></span>
                      </label> 
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.write"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.create"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.delete"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.import"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.export"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Assets</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.asset.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.write"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.create"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.delete"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.import"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.export"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Timing Sheets</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.write"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.create"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.delete"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.import"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.export"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Overtime</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.write"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.create"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.delete"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.import"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.export"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Office</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.office.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.write"
                          :disabled="!permissions.office.read"

                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.create"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.delete"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.import"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.export"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Submit</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Designation Modal -->

  <!-- Edit Designation Modal -->
  <div id="edit_designation" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Designation</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="editdesgclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <Form @submit="onUpdate" :validation-schema="editschema" v-slot="{ errors }">
            <div class="input-block mb-3">
              <label class="col-form-label"
                >Designation Name <span class="text-danger">*</span></label
              >
              <Field
                    name="name"
                    type="text"
                    value=""
                    class="form-control"
					v-model="editform.name"
					:class="{ 'is-invalid': errors.name }"
                  />
                  <div class="invalid-feedback">{{ errors.name }}</div>
                  <div class="nameshow text-danger" id="name"></div>
            </div>
            <div class="input-block mb-3">
              <div class="form-check" style="padding-left:0px">
                <label class="form-check-label" for="customCheck3" style="margin-left:5px"
                  >Is Reporter ? </label
                >
                <input
                  name="is_reporter"
                  type="checkbox"
                  class="days recurring"
                  id="customCheck3"
                  :value="1"
                  v-model="editform.is_reporter"
                  :checked="editform.is_reporter"
                  style="margin-left: 10px"
                />
                <div class="invalid-feedback">{{ errors.is_reporter }}</div>
                <div class="is_reportershow text-danger" id="is_reporter"></div>
              </div>
            </div>
            <div class="">
              <table class="table table-striped custom-table">
                <thead>
                  <tr>
                    <th>Module Permission</th>
                    <!-- <th class="text-center">Show Navbar</th> -->
                    <th class="text-center">Read</th>
                    <th class="text-center">Write</th>
                    <th class="text-center">Create</th>
                    <th class="text-center">Delete</th>
                    <th class="text-center">Import</th>
                    <th class="text-center">Export</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Holidays</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.write"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.create"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.delete"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.import"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.holiday.export"
                          :disabled="!permissions.holiday.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Employee</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.employee.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.write"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.create"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.delete"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.import"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.employee.export"
                          :disabled="!permissions.employee.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Attendance</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox"
                        name="rememberme"
                        class="rememberme" v-model="permissions.attendance.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox"
                        name="rememberme"
                        class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.write"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.create"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.delete"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.import"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.attendance.export"
                          :disabled="!permissions.attendance.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Department</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.department.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.write"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.create"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.delete"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.import"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.department.export"
                          :disabled="!permissions.department.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Designation</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.designation.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.write"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.create"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.delete"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.import"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.designation.export"
                          :disabled="!permissions.designation.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Wages</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.wages.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.write"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.create"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.delete"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.import"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.wages.export"
                          :disabled="!permissions.wages.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Schedule</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.write"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.create"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.delete"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.import"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.schedule.export"
                          :disabled="!permissions.schedule.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Shift</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.shift.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.write"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.create"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.delete"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.import"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.shift.export"
                          :disabled="!permissions.shift.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Leaves</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.leave.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.write"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.create"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.delete"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.import"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.leave.export"
                          :disabled="!permissions.leave.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Clients</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.client.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.write"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.create"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.delete"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.import"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.client.export"
                          :disabled="!permissions.client.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Projects</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.project.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.write"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.create"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.delete"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.import"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.project.export"
                          :disabled="!permissions.project.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Tasks</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.task.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.write"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.create"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.delete"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.import"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.task.export"
                          :disabled="!permissions.task.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Chats</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.chat.show_navbar" />
                        <span class="checkmark"></span>
                      </label> 
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.write"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.create"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.delete"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.import"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.chat.export"
                          :disabled="!permissions.chat.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Assets</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.asset.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.write"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.create"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.delete"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.import"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.asset.export"
                          :disabled="!permissions.asset.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Timing Sheets</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.write"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.create"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.delete"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.import"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.timing_sheet.export"
                          :disabled="!permissions.timing_sheet.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Overtime</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.write"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.create"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.delete"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.import"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.overtime.export"
                          :disabled="!permissions.overtime.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Office</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.office.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.write"
                          :disabled="!permissions.office.read"

                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.create"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.delete"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.import"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.office.export"
                           :disabled="!permissions.office.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Organization Documents</td>
                    <!-- <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.office.show_navbar" />
                        <span class="checkmark"></span>
                      </label>
                    </td> -->
                    <td class="text-center">
                      <label class="custom_check">
                        <input type="checkbox" v-model="permissions.organization_document.read" @change="handlePermissionChangeForRead('organization_document')" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.organization_document.write"
                          :disabled="!permissions.organization_document.read"

                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.organization_document.create"
                           :disabled="!permissions.organization_document.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.organization_document.delete"
                           :disabled="!permissions.organization_document.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.organization_document.import"
                           :disabled="!permissions.organization_document.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td class="text-center">
                      <label class="custom_check">
                        <input
                          type="checkbox"
                          name="rememberme"
                          class="rememberme"
                          v-model="permissions.organization_document.export"
                           :disabled="!permissions.organization_document.read"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn">Save</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Designation Modal -->

  <!-- Delete Designation Modal -->
  <div class="modal custom-modal fade" id="delete_designation" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Designation</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteDesignation"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn" ref="deletedesgclose"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Designation Modal -->
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
export default {
  emits: ["createDesignation","updateDesignation","deleteDesignation"], // <--- add this line
  components: {
    Form,
    Field,
  },
  props: {
    form: [Array, Object],
    departmentlist: [Array, Object],
	editform: [Array, Object],
	deldes_id:[Number,String]
  },
  data() {
    return {
      select2: null,
      Department: ["Select Department", "Web Development", "IT Management", "Marketing"],
      permissions:{
          holiday: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          employee: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          department: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          designation: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          wages: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          schedule: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          shift: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          leave: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          client: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          project: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          task: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          chat: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          asset: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          timing_sheet: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          overtime: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          leave_type: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          attendance: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          incident: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          office: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          organization_document: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          }
        },
    };
  },
  methods: {
  handlePermissionChangeForRead(module = null)
  {
    const readPermission = this?.permissions?.[module]?.read;

    if(!readPermission)
    {
      this.permissions[module].create = false;
      this.permissions[module].write = false;
      this.permissions[module].delete = false;
      this.permissions[module].import = false;
      this.permissions[module].export = false;
    }          
  },
	deleteDesignation(){
	
		this.$emit("delete-designation",this.deldes_id);
	},
	onSubmit(values) {

    values.permissions = this.permissions
    values.is_reporter = this.form.is_reporter
		this.$emit("create-designation",values);
	},
	onUpdate(values){
    this.editform.permissions = this.permissions;
    this.$nextTick(()=>{
      this.$emit("update-designation",this.editform);
    })
	},
	closeDialog(){
		this.$refs.adddesgclose.click();
		this.$refs.editdesgclose.click();
		this.$refs.deletedesgclose.click();
	},
  resetPermission()
  {
      this.permissions = {
        holiday: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        employee: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        department: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        designation: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        wages: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        schedule: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        shift: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        leave: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        client: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        project: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        task: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        chat: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        asset: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        timing_sheet: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        overtime: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        leave_type: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        attendance: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        incident: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        office: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        },
        organization_document: {
          read: false,
          write: false,
          delete: false,
          create: false,
          export: false,
          import: false
        }
      }
  }
  },
  watch:{
    editform(newVal)
    {

      if(newVal?.permissions && newVal?.permissions != null )
      {
        this.permissions =  JSON.parse(newVal?.permissions);
        
      }
      else{
        this.resetPermission()
      }
      
    }
  },
  setup() {
    const schema = Yup.object().shape({
      designation_name: Yup.string().required("Designation Name is required"),
    });
	
	const editschema = Yup.object().shape({
      name: Yup.string().required("Designation Name is required"),
    });
	
    return {
      schema,
	  editschema
    };
  },
};
</script>
