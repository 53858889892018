<template>
  <div>
    <div class="tab-pane fade" id="emp_assets">
      <div v-if="activeTab == 'emp_assets'">
        <div class="showentries mb-3">
          <label
            >Show
            <select v-model="pagination.pageSize" @change="updatePagination" >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div class="table-responsive table-newdatatable">
          <a-table class="stripped table-hover" :columns="columns" :data-source="assetData" :pagination="pagination"
                  @change="handleTableChange">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'asset_name'">
                <router-link :to="'/assets-details/'+record.asset_id" class="table-imgname">
                  <div v-if="record.files && record.files.length" class="table-profileimage">
                    <img v-if="record.files && record.files.length"
                      :src="getImages(record?.files)"
                      class="me-2 rounded-circle" 
                      @error="handleImageAssetError(record)"
                    />
                  </div>
                  <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                        class="me-2 rounded-circle" 
                    />
                  <span>{{ record?.asset?.name }}</span>
                </router-link>
              </template>
              <template v-if="column.key === 'asset_id'">
                <router-link :to="'/assets-details/'+record.asset_id" class="table-imgname">
                  
                  <span>{{ record?.asset?.uuid }}</span>
                </router-link>
              </template>
              <template v-if="column.key === 'assignee_date'">
                <router-link :to="'/assets-details/'+record.asset_id" class="table-imgname">
                  
                  <span>{{ formattedDate(record?.asset?.created_at) }}</span>
                </router-link>
              </template>
              <template v-if="column.key === 'assignee'">
                <div class="table-namesplit">
                  <a href="javascript:void(0);" class="table-profileimage">
                    <img v-if="record?.assignee?.avatar && record?.assignee?.avatar != null && getEmployeeAvatar(record.assignee.avatar) != null"
                        :src="getEmployeeAvatar(record.assignee.avatar)"
                        alt="User Image"
                        class="me-2"
                        @error="handleImageError(record)"
                    />
                    <img v-else
                    :src="require(`@/assets/img/profiles/${defaultAvatar}`)"
                        alt="User Image"
                        class="me-2"
                    />
                  </a> 
                  <a href="javascript:void(0);" class="table-name">
                    <span>{{ record?.assignee?.first_name }} {{ record?.assignee?.last_name }}</span>
                    <p>{{ record?.assignee?.email }}</p>
                  </a>
                </div>
              </template>
              <template v-if="column.key === 'action'">
                <router-link :to="'/assets-details/'+record.asset_id">
                  <div class="table-actions d-flex cursor-pointer">
                  <img :src="require(`@/assets/img/icons/eye.svg`)" alt="Eye Icon" />
                  </div>
                </router-link>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <assetmodel />
  </div>
</template>
<script>
import { mapActions,mapGetters  } from 'vuex';
import moment from 'moment';
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};
export default {
  data() {
    return {
      columns:[
        {
          title: "#",
          dataIndex: "index",
          key:'index',
          customRender: ({ index }) => {
                return (index + 1);
            },
        },
        {
          title: "Name",
          dataIndex: "asset_name",
          key: "asset_name",
          sorter: {
            compare: (a, b) => {
              a = a?.asset?.name?.toLowerCase();
              b = b?.asset?.name?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
        },
        {
          title: "Asset ID",
          dataIndex: "asset_id",
          key:'asset_id',
          sorter: {
            compare: (a, b) => {
              a = a?.asset?.uuid?.toLowerCase();
              b = b?.asset?.uuid?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
        },
        {
          title: "Assigned Date",
          dataIndex: "assignee_date",
          key:'assignee_date',
          sorter: {
            compare: (a, b) => {
              a = a?.asset?.created_at?.toLowerCase();
              b = b?.asset?.created_at?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
        },
        {
          title: "Assignee",
          dataIndex: "assignee",
          key: "assignee",
          sorter: {
            compare: (a, b) => {
              a = a?.assignee?.first_name?.toLowerCase();
              b = b?.assignee?.first_name?.toLowerCase();
              return a > b ? -1 : b > a ? 1 : 0;
            },
          },
        },

        {
          title: "Action",
          key: "action",
          sorter: true,
        },
      ],
      assetData:null,
      defaultAvatar:'avatar-02.jpg',
      perpage: 1,
      pagination: pagination,
    };
  },
  props:{
    employeeID: {
      type: Number,
      default: null,
      required:false
    },
    activeTab:{
        type: String,
        default: null,
        required:false 
    }
  },
  watch:{
    async employeeID(newVal)
    {
      if(newVal)
      {
        this.assetData = await this.getEmployeeAsset(newVal);
      }
      else
      {
        this.assetData = null;
      }
    }
  },
  methods:{
    ...mapActions(['getEmployeeAsset']),
    handleImageAssetError(record) {
      record.files =[{file_path : null,document_type:'png'}];
      record.files[0].file_path = `assets/img/profiles/${this.defaultAvatar}`;
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      //this.fetchData(params,null);
    },
    updatePagination(){
      // this.fetchData(null,null);
    },
    handleImageError(record) {
      record.assignee.avatar = null;
      record.assignee.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
    getImages(images){
      if(images && images.length)
      {
        const image = images.find(image => {
        const documentType = image?.document_type?.toLowerCase();
            return documentType?.includes('jpg') || documentType?.includes('jpeg') || documentType?.includes('png');
        });

        if(image?.file_path != '')
        {
          return `${process.env.VUE_APP_IMAGE_BASE_URL}${image?.file_path}`
        }
        else
        {
          return null
        }
        
      }
      else{
        return null
      }
    
    },
    getEmployeeAvatar(avatar){
      if(avatar && avatar != null )
      {
        return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
      }
      else{
        return null
      }
    },
    formattedDate(date_) {
      const formattedDate = moment(date_).format('YYYY-MM-DD');
        return formattedDate
      },
  },
  async created(){
  }
};
</script>
