<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission" :hasImportPermission="hasImportPermission"/>
        <!-- /Page Header -->
		
		<Form @submit="onSearch">
			<div class="row filter-row">
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
					<input
					  type="text"
					  class="form-control floating"
					  v-model="searchform.name"
					  @focus="isFocused = true"
					  @blur="isFocused = searchform.name !== ''"
					/>
					<label class="focus-label"> Title</label>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus">
					<div class="cal-icon">
					  <datepicker
						v-model="searchform.s_from"
						placeholder="From"
						class="form-control floating datetimepicker"
						:editable="true"
						:clearable="false"
						 :input-format="dateFormat"
					  />
					</div>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <div class="input-block mb-3 form-focus">
					<div class="cal-icon">
					  <datepicker
						v-model="searchform.s_to"
						placeholder="To"
						class="form-control floating datetimepicker"
						:editable="true"
						:clearable="false"
						 :input-format="dateFormat"
					  />
					</div>
				  </div>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
				  <button class="btn btn-success w-100 submit-btn">Search</button>
				</div>
				<div class="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12" style="margin-left: 10px;">
				  <a href="javascript:;" class="btn btn-danger w-100 submit-btn" @click="clearForm"> Clear </a>
				</div>
			  </div>
		</Form>  
		
		<div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
				  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div>
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <div>{{ record.Name }}</div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_holiday"
							@click="EditHoliday(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_holiday"
							@click="DeleteHoli(record.id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
		
		<!--
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped custom-table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Holiday Date</th>
                    <th>End Date</th>
                    <th class="text-end">Action</th>
                  </tr>
                </thead>
                <tbody>
				 <tr class="holiday-upcoming" v-for="(item,index) in Holidays" :key="item.id">
					<td>{{ pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1) }}</td>
					<td>{{ item.name }}</td>
					<td>{{ item.holiday_date }}</td>
					<td>{{ item.end_date }}</td>
					<td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_holiday"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_holiday"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
				 </tr>
                 <tr class="holiday-upcoming" v-for="item in Holidays" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.Title }}</td>
                    <td>{{ item.HolidayDate }}</td>
                    <td>{{ item.Day }}</td>
                    <td class="text-end">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_holiday"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_holiday"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
				  
                </tbody>
              </table>
            </div>
          </div>
        </div> -->
      </div>
      <!-- /Page Content -->

      <holiday-model :form="create_form" @create-holiday="createHoliday" @update-holiday="updateHoliday" :editform="edit_form" :holiday_id="holidayid" @delete-holiday="deleteHoliday" ref="holidaysmodel" ></holiday-model>

	  <!-- Import holiday Modal -->
	  <div id="import_Holiday" class="modal custom-modal fade" role="dialog">
		<div class="modal-dialog modal-dialog-centered" role="document">
		<div class="modal-content">
		  <div class="modal-header">
		  <h5 class="modal-title">Import Holidays</h5>
		  <button
			type="button"
			class="btn-close"
			data-bs-dismiss="modal"
			aria-label="Close"
			ref="impholidayclose"
		  >
			<span aria-hidden="true">&times;</span>
		  </button>
		  </div>
		  <div class="modal-body">
		  <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
			<div class="input-block mb-3">
			<label class="col-form-label"
			  >File <span class="text-danger">*</span>
			</label>
			<Field
				name="holiday_import_file"
				type="file"
				class="form-control"
				:class="{ 'is-invalid': errors && errors?.holiday_import_file }"
				@change="handleFileUpload($event)"
				accept=".xlsx,.xls"	
				ref="importFile"
			  />
			  <div class="invalid-feedback">{{ errors && errors?.holiday_import_file }}</div>
			  <div class="filesshow text-danger" id="file"></div>
			  <p> <a :href="user_demo_file" target="_blank">Click here </a> for download Demo file </p>
			</div>
			<div class="submit-section">
			<button class="btn btn-primary submit-btn">Submit</button>
			</div>
		  </Form>
		  </div>
		</div>
		</div>
	  </div>
	  <!-- /Import holiday Modal -->

    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>

var pagination = {total: 0,current: 1,pageSize: 10};
		
const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Title",
    dataIndex: "name",
    key: "name",
	
    /*sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },*/
  },
  {
    title: "Holiday Date",
    dataIndex: "holiday_date",
    key: "holiday_date",
	sorter: false,
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
	sorter: false,
  },
//   {
//     title: "Action",
//     sorter: false,
//     key: "action",
//     class: "text-end",
//   },
];

const data = [];		

		
//import Holidays from "@/assets/json/holidays.json";
import inboxHeader from "@/components/breadcrumb/inbox-header.vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
import { ref } from "vue";
const currentDate = ref(new Date());

export default {
  components: { inboxHeader,Form,Field},
  data() {
    return {
	  isFocused : false,
	  dateFormat: 'dd-MM-yyyy',
      Holidays: {},
      title: "Holidays",
      path: "Dashboard",
      text: "Holidays",
      text1: "Add Holiday",
	  pagination: pagination,
	  columns:columns,
	  data,
	  create_form: { "name": "", "holiday_date": currentDate, "end_date" :currentDate},
	  searchform: {name: "",s_from:"",s_to:""},
	  edit_form: {},
	  holidayid:0,
	  hasReadPermission:null,
	  hasCreatePermission:null,
	  hasEditPermission:null,
	  hasDeletePermission:null,
	  editfile:null,
	  errors:null,
	  hasImportPermission:null,
    };
  },
  computed:{
    user_demo_file(){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/holiday.xlsx` 
    }
  },
  methods: {
	clearForm(){
		
		this.searchform = {name: "",s_from:"",s_to:""};
		
		var params = {
		   params: { per_page: this.pagination.pageSize }
		};	
		this.loadCommonData(params);
	
	},
	onSearch(){
		console.log('testing...');
		
		var s_from = this.searchform.s_from;
		
		if(s_from != ""){
			s_from = moment(s_from).format('YYYY-MM-DD');
		}
		
		var s_to = this.searchform.s_to;
		if(s_to != ""){
			s_to = moment(s_to).format('YYYY-MM-DD');
		}
		
		var params = {
		   params: { per_page: this.pagination.pageSize,page:this.pagination.current, name: this.searchform.name,s_from:s_from, s_to: s_to }
		};	
		
		this.loadCommonData(params);
		
	},
	deleteHoliday(holidayid){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/holidays/"+holidayid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.holidaysmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	DeleteHoli(holidayid){
		this.holidayid = holidayid;
	},
	updateHoliday(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
			
		var postform = new FormData();
		postform.append('name',formval.name);
		postform.append('holiday_date',moment(formval.holiday_date).format('YYYY-MM-DD'));
		postform.append('end_date',moment(formval.end_date).format('YYYY-MM-DD'));
		
		axios.put("/holidays/"+formval.id, postform)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.holidaysmodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	EditHoliday(record){
		
		//this.edit_form = Object.assign({}, record);
		
		var editobj = Object.assign({}, record);
		
		if(editobj.holiday_date != ""){
			editobj.holiday_date = new Date(editobj.holiday_date);
		}
		
		if(editobj.end_date != ""){
			editobj.end_date = new Date(editobj.end_date);
		}
		
		this.edit_form = editobj;
		
	},
	createHoliday(formval){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/holidays", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.holidaysmodel.closeDialog();
			 
			 this.create_form =  { "name": "", "holiday_date": currentDate, "end_date" :currentDate};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
	},
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current }
		};
		
		this.loadCommonData(params);
		
	},
	updatePerPage(){
		var params = {
          params: { per_page: this.pagination.pageSize }
        };
        this.loadCommonData(params);
		
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/holidays", params)
			.then((response) => {
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				this.data = response.data.data.data;
				
				loader.hide();
				
				
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	handleFileUpload(event){
      this.editfile = event.target.files[0];
    },
	onSubmit()
	{
		var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

		let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

		var form = new FormData();
        form.append('holiday_import_file', this.editfile);

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
		};

		axios.post("/holidays/import", form,config).then(response => {

			this.editfile = null
          	this.$refs.importFile.value = ''
			loader.hide();

			this.updatePerPage()
			
			notification.open({
              message: response?.data?.message || 'Successfully Imported',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

			this.$refs.impholidayclose.click();

		}).catch(error =>{
			
			loader.hide();
			if(error.response){
          
				var response = (error.response);
					
				notification.open({
					message: response?.data?.message || 'Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
				}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
		})

	}
  },
  setup() {
    const schema = Yup.object().shape({
		holiday_import_file: Yup.string().required("File is required"),
    });
	return {
      schema
	}
  },
  mounted() {
	this.$store.dispatch('userPermissions', { module: 'holiday', action: 'read' }).then(response =>{
		this.hasReadPermission=null;
		this.$nextTick(()=>{
			this.hasReadPermission = response;

			if(response)
			{
				this.columns = [...columns,{
					title: "Action",
					sorter: false,
					key: "action",
					class: "text-end",
				}];
			}
		})
	})


	this.$store.dispatch('userPermissions', { module: 'holiday', action: 'create' }).then(response =>{
		this.hasCreatePermission=null;
		this.$nextTick(()=>{
			this.hasCreatePermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'holiday', action: 'write' }).then(response =>{
		this.hasEditPermission=null;
		this.$nextTick(()=>{
			this.hasEditPermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'holiday', action: 'delete' }).then(response =>{
		this.hasDeletePermission=null;
		this.$nextTick(()=>{
			this.hasDeletePermission = response;
		})
	})

	this.$store.dispatch('userPermissions', { module: 'holiday', action: 'import' }).then(response =>{
		this.hasImportPermission=null;
		this.$nextTick(()=>{
			this.hasImportPermission = response;
		})
	})
	
	var params = {
       params: { per_page: this.pagination.pageSize }
    };	
	this.loadCommonData(params);
	
  },
  
};
</script>
