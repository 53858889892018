<template>
<div class="main-wrapper">
      <layout-header></layout-header>
      <layout-sidebar></layout-sidebar>
      <div class="page-wrapper" ref="formContainer">
            <div class="content container-fluid">
                <incident-breadcrumb @clean-incident-data="clearFormData" title="Incidents" path="Dashboard" text="Incidents" text1="Add Incidents" /> 
                
                <div>
                    <!-- <div class="row">
                        <div class="col-auto float-end ms-auto">
                            <a @click="clearFormData" href="javascript:;" class="btn add-btn m-r-5" data-bs-toggle="modal" data-bs-target="#add_Incident"> <i class="fa-solid fa-plus"></i> Add Incident</a>
                        </div>
                    </div> -->
                    <div class="showentries mb-3">
                        <label
                        >Show
                        <select v-model="pagination.pageSize" @change="fetchData()">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        entries
                        </label>
                    </div>
                    <div class="table-responsive table-newdatatable">
                        <a-table class="stripped table-hover" :columns="columns" :data-source="data" :pagination="pagination"
                            @change="handleTableChange">
                            <template #bodyCell="{ column, record }">
                                <template v-if="column.key === 'incident_by'">
                                    <h2 class="table-avatar">
                                        <router-link :to="'profile/'+record?.employee_id" class="avatar"
                                                    >
                                                    <img v-if="record?.employee?.avatar"
                                                    :src="getEmployeeAvatar(record?.employee?.avatar)"
                                                    alt="User Image"
                                                    @error="handleIncidentByImageError(record)"
                                                    />
                                                    <img v-else
                                                    :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                                                        alt="User Image" 
                                                    />
                                        </router-link>
                                        <router-link :to="'profile/'+record?.employee_id"
                                            >{{ record?.employee?.first_name }} {{ record?.employee?.last_name }} 
                                        </router-link>
                                    </h2>
                                </template>
                                <template v-if="column.key === 'created_by'">
                                    <h2 class="table-avatar">
                                        <router-link :to="'profile/'+record?.created_by" class="avatar"
                                                    >
                                                    <img v-if="record?.creator?.avatar"
                                                    :src="getEmployeeAvatar(record?.creator?.avatar)"
                                                    alt="User Image"
                                                    @error="handleImageError(record)"
                                                    />
                                                    <img v-else
                                                    :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                                                        alt="User Image" 
                                                    />
                                        </router-link>
                                        <router-link :to="'profile/'+record?.created_by"
                                            >{{ record?.creator?.first_name }} {{ record?.creator?.last_name }} 
                                        </router-link>
                                    </h2>
                                </template>
                                <template v-if="column.key === 'created_at'">
                                    <div> {{ formateDate(record?.created_at) }}</div>
                                </template>
                                <template v-else-if="column.key === 'action'">
                                    <div class="text-start" v-if="hasEditPermission || hasDeletePermission">
                                        <div class="dropdown dropdown-action">
                                            <a
                                            href="javascript:;"
                                            class="action-icon dropdown-toggle"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                            ><i class="material-icons">more_vert</i></a
                                            >
                                            <div class="dropdown-menu dropdown-menu-right">
                                            <a  v-if="hasEditPermission"
                                                class="dropdown-item"
                                                href="javascript:;"
                                                data-bs-toggle="modal" 
                                                data-bs-target="#add_Incident"
                                                @click="editIncident(record)"
                                                ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                                            >
                                            <a v-if="hasDeletePermission"
                                                class="dropdown-item"
                                                href="javascript:;"
                                                data-bs-toggle="modal"
                                                data-bs-target="#delete_incident"
                                                @click="delete_id = record?.incident_id"
                                                ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                                            >
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </a-table>
                    </div>
                </div>
        
                <!-- Delete Incident Modal -->
                <div class="modal custom-modal fade" id="delete_incident" role="dialog">
                    <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                        <div class="form-header">
                            <h3>Delete Incident</h3>
                            <p>Are you sure want to delete?</p>
                        </div>
                        <div class="modal-btn delete-action">
                            <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="deleteIncident"
                                >Delete</a
                                >
                            </div>
                            <div class="col-6">
                                <a
                                href="javascript:void(0);"
                                data-bs-dismiss="modal"
                                class="btn btn-primary cancel-btn"
                                ref="close_incident_delete_model"
                                >Cancel</a
                                >
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- /Delete Incident Modal -->

                <!-- Delete Incident Doc Modal -->
                <div class="modal custom-modal fade" id="delete_incident_doc" role="dialog">
                    <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                        <div class="form-header">
                            <h3>Delete Document</h3>
                            <p>Are you sure want to delete?</p>
                        </div>
                        <div class="modal-btn delete-action">
                            <div class="row">
                            <div class="col-6">
                                <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="delete_doc()"
                                >Delete</a
                                >
                            </div>
                            <div class="col-6">
                                <a
                                ref="incident_doc_delete"
                                href="javascript:void(0);"
                                data-bs-dismiss="modal"
                                class="btn btn-primary cancel-btn"
                                >Cancel</a
                                >
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <!-- /Delete Incident Doc Modal -->

                <!-- Add Incident Modal -->
                <div id="add_Incident" class="modal custom-modal fade" role="dialog">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title"> {{ incident?.incident_id ? 'Edit' : 'Add ' }} Incident</h5>
                                <button
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                ref="add_Incident_close"
                                >
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <label class="col-form-label">Incident Done By <span class="text-danger">*</span></label>
                                        <select
                                        v-model="incident.employee_id"
                                        @change="handleChangeEvent('employee_id')"
                                        :class="{ 'is-invalid': clientError.includes('employee_id') }"
                                        class="form-select form-select-lg"
                                        >
                                            <option v-for="emp in employees" :key="'emp'+emp.id" :value="emp.id">
                                                {{ emp.first_name }} {{ emp.last_name }}
                                            </option>
                                        </select>
                                        <span class="text-danger" v-if="incident_errors && incident_errors?.employee_id && incident_errors?.employee_id != null">{{ incident_errors?.employee_id[0]  }}</span>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="input-block mb-3">
                                            <label class="col-form-label"
                                                >Incident Name <span class="text-danger">*</span></label
                                            >
                                            <input @change="handleChangeEvent('incident_name')" v-model="incident.incident_name" class="form-control" type="text" :class="{ 'is-invalid': clientError.includes('incident_name') }" />
                                            <span class="text-danger" v-if="incident_errors && incident_errors?.incident_name && incident_errors?.incident_name != null">{{ incident_errors?.incident_name[0]  }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="input-block mb-3">
                                            <label class="col-form-label"
                                                >Incident Description <span class="text-danger">*</span></label
                                            >
                                            <textarea @change="handleChangeEvent('incident_description')" v-model="incident.incident_description" class="form-control" rows="4" :class="{ 'is-invalid': clientError.includes('incident_description') }">
                                            </textarea>
                                            <span class="text-danger" v-if="incident_errors && incident_errors?.incident_description && incident_errors?.incident_description != null">{{ incident_errors?.incident_description[0]  }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="col-form-label">Incident Date 
                                            <span class="text-danger">*</span></label>
                                        <datepicker
                                            v-model="startdate"
                                            placeholder="Choose Date"
                                            class="form-control floating datetimepicker"
                                            :editable="true"
                                            :clearable="false"
                                            :class="{ 'is-invalid': clientError.includes('date') }"
                                            @change="handleChangeEvent('date')"
                                            />
                                        <span class="text-danger" v-if="incident_errors && incident_errors?.date && incident_errors?.date != null">{{ incident_errors?.date[0]  }}</span>
                                        
                                    </div>
                                    <div class="col-sm-12">
                                        <label class="control-label col-form-label">Attach File</label>
                                        <div>
                                            <input
                                                type="file"
                                                class="form-control"
                                                data-buttontext="Choose File"
                                                data-icon="false"
                                                data-classbutton="btn btn-default"
                                                data-classinput="form-control inline input-s"
                                                name="documents[]"
                                                multiple
                                                accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                                                ref="attachments" 
                                            />
                                        </div>
                                    </div>
                                    <div class="col-sm-12" v-if="incident?.incident_id && incident?.documents?.length">
                                        <br>
                                        <h4> Uploaded Documents</h4>
                                    
                                        <table class="table table-responsive">
                                            <tr v-for="incdnt in incident.documents" :key="'incident_doc_'+incdnt.incident_id">
                                                <td>
                                                <a :href="docPath( incdnt?.file_path )" target="_blank"> {{ incdnt?.original_file_name }} </a> 
                                                </td>
                                                <td>
                                                    <a href="javascript:;" data-bs-toggle="modal"  @click="delete_doc_id = incdnt?.incident_document_id"
                                                data-bs-target="#delete_incident_doc"><i class="fa fa-trash-can m-r-5 text-danger"></i> Delete </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class="submit-section">
                                    <button class="btn btn-primary submit-btn" :disabled="disableButton" @click="onAddIncident">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>

</template>
<script>
import axios from 'axios';
import { notification } from "ant-design-vue";
import moment from 'moment';
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};

export default {
  data() {
    return {
        columns:[],
        column:[
            {
            title: "#",
            dataIndex: "index",
            key:'index',
            customRender: ({ index }) => {
                return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
            },
            },
            {
                title: "Incident Name",
                dataIndex: "incident_name",
                key: "incident_name",
                sorter: {
                    compare: (a, b) => {
                    a = a.incident_name.toLowerCase();
                    b = b.incident_name.toLowerCase();
                    return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: "Incident By",
                dataIndex: "incident_by",
                key: "incident_by",
                sorter: {
                    compare: (a, b) => {
                    a = a?.employee?.first_name?.toLowerCase();
                    b = b?.employee?.first_name?.toLowerCase();
                    return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: "Reported by",
                dataIndex: "created_by",
                key: "created_by",
                sorter: {
                    compare: (a, b) => {
                    a = a.creator?.first_name?.toLowerCase();
                    b = a.creator?.first_name?.toLowerCase();
                    return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
            {
                title: "Reported At",
                dataIndex: "created_at",
                key: "created_at",
                sorter: {
                    compare: (a, b) => {
                    a = a.created_at.toLowerCase();
                    b = b.created_at.toLowerCase();
                    return a > b ? -1 : b > a ? 1 : 0;
                    },
                },
            },
        ],
        data:[],
        perpage: 10,
        pagination: pagination,
        delete_id:null,
        incident:{
            incident_name:'',
            incident_description:'',
            documents:[],
            incident_id:null
        },
        incident_errors:[],
        clientError:[],
        disableButton:false,
        delete_doc_id:null,
        startdate:new Date(),
        employees:[],
        hasReadPermission:null,
        hasEditPermission:null,
        hasDeletePermission:null,
        today:new Date()
    }
  },
  watch:{
    startdate()
    {
        if(this.startdate)
        {
            if(moment(this.startdate) > moment())
            {
                this.clientError.push('date');
            }
            else
            {
                this.clientError = this.clientError.filter(item => item !== 'date');
            }
        }
    }
    // async employeeID(newVal)
    // {
    //   if(newVal)
    //   {
    //     await this.fetchData();
    //   }
    // },
  },
  props:{
    // employeeID: {
    //   type: Number,
    //   default: null,
    //   required:false
    // },
    activeTab:{
        type: String,
        default: null,
        required:false 
    }
  },
  methods:{
    handleChangeEvent(target)
    {
      this.clientError = this.clientError.filter(item => item !== target);
    },
    async delete_doc()
    {
        const incident_document_id = this.delete_doc_id;

        if(incident_document_id)
        {
            var token = window.localStorage.getItem("token");
	
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            await axios.delete('/employee/incident/document/'+incident_document_id).then(response=>{

                this.fetchData();

                notification.open({
                    message: response?.data?.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

                this.$refs.incident_doc_delete.click()
                

            }).catch(error=>{
                if(error.response)
                {
                var response = (error.response);
                    
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });

                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }

                this.$refs.incident_doc_delete.click()
            })

            this.delete_doc_id = null
        }
    },
    docPath(path)
    {
        return `${process.env.VUE_APP_IMAGE_BASE_URL}${path}`
    },
    editIncident(record)
    {
        this.clientError = [];
        this.incident_errors =[];
        this.incident = record

        this.startdate = record?.date ? new Date(record.date) : null
    },
    clearFormData()
    {
        this.$refs.attachments.value = '';
        this.clientError = [];
        this.incident_errors =[];
        this.disableButton = false;
        this.incident = {
            incident_name:'',
            incident_description:'',
            documents:[],
            incident_id:null,
            employee_id:null
        }
    },
    async onAddIncident()
    {
        this.clientError = []
        this.incident_errors = []
        this.disableButton = true

        if(!this.incident.incident_name?.trim()?.length)
        {
            this.clientError.push('incident_name')
        }

        if(!this.incident.incident_description?.trim()?.length)
        {
            this.clientError.push('incident_description')
        }

        if(!this.incident.employee_id)
        {
            this.clientError.push('employee_id')
        }


        if (!isNaN(this.startdate.getTime())) {
            var dd = this.startdate;
            dd.setMonth(dd.getMonth() + 1);
            const year = dd.getFullYear();
            const month = dd.getMonth().toString().padStart(2, '0');
            const day = dd.getDate().toString().padStart(2, '0');
            this.incident.date = `${year}-${month}-${day}`;
        }
        else
        {
            this.incident.date = null;
            this.clientError.push('date');
        }

        if(this.clientError.length)
        {
            this.disableButton = false;
            return false;
        }

        const formData = new FormData();
        formData.append('incident_name',this.incident.incident_name);
        formData.append('incident_description',this.incident.incident_description);
        formData.append('employee_id',this.incident.employee_id);
        formData.append('date',this.incident.date);

        const filesInput = this.$refs.attachments;
            for (let i = 0; i < filesInput.files.length; i++) {
            formData.append('documents[]', filesInput.files[i]);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };
        
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if(this.incident?.incident_id != null)
        {
            await axios.post('/employee/incident/update/'+this.incident?.incident_id,formData,config).then(response => {

            this.disableButton = false
            this.$refs.attachments.value = '';
            this.$refs.add_Incident_close.click();
            this.fetchData();

            notification.open({
                message: response?.data?.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            this.clearFormData();

            }).catch(error => {
                this.disableButton = false
               
                if(error.response){

                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            });
        }
        else
        {
            await axios.post('/employee/incident',formData,config).then(response => {

            this.disableButton = false
            this.$refs.attachments.value = '';
            this.$refs.add_Incident_close.click();
            this.fetchData();

            notification.open({
                message: response?.data?.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
                },
            });

            this.clearFormData();

            }).catch(error => {
                this.disableButton = false

                this.incident_errors = error?.response?.data?.error;

                if(error.response){

                    var response = (error.response);
                        
                    notification.open({
                        message: response.data.message,
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            });
        }

        

       
    },
    async deleteIncident(){

        if(this.delete_id != null)
        {
            var token = window.localStorage.getItem("token");
	
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;

            await axios.delete('/employee/incident/'+this.delete_id).then(response=>{

                this.fetchData();

                notification.open({
                    message: response?.data?.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                    },
                });

            }).catch(error=>{
                if(error.response)
                {
                var response = (error.response);
                    
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });

                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            })
        }

        this.$refs?.close_incident_delete_model.click()

        this.delete_id = null

    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.fetchData(params);
    },
    async fetchData(params = null)
    {
        var param = null

        if(!params)
        {
          param = {
            params: { per_page: this.pagination.pageSize }
            };
        }
        else
        {
            param = params
        }

        // const empId = this.employeeID;
        
        // if(empId)
        // {
            var token = window.localStorage.getItem("token");
	
            axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            
            let loader = this.$loading.show({
                container: this.$refs.formContainer,
                canCancel: false
            });

            await axios.get('/incident',param).then(response => {
                this.data = response?.data?.record?.data;

                this.pagination.total = response?.data?.record?.total;
                this.pagination.current = response?.data?.record?.current_page;
                this.pagination.pageSize = response?.data?.record?.per_page;

                loader.hide();
            }).catch(error => {
                loader.hide();
                if(error.response){
			
                var response = (error.response);
                    
                notification.open({
                    message: response.data.message,
                    placement: "topRight",
                    duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                    style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                    },
                });
                    
                }else{
                    
                    notification.open({
                        message: 'Server Error',
                        placement: "topRight",
                        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                        style: {
                        background: process.env.VUE_APP_WARNING_COLOR,
                        },
                    });
                }
            })
        // }
        
            
      //  axios.get('/')
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
        record.creator.avatar = null;
        record.creator.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    handleIncidentByImageError(record) {
        record.employee.avatar = null;
        record.employee.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    formateDate(date)
    {
        return moment(date).format('YYYY-MM-DD');
    },
    async getEmployee(){

        var token = window.localStorage.getItem("token");

        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        await axios.get('/employees/all').then(response=>{

        this.employees = response?.data?.data ? response?.data?.data : []
        }).catch(error => {
        if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                },
                });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
        }
        
        if(error?.response){
        
            var response = (error.response);
            
            notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
                background: process.env.VUE_APP_WARNING_COLOR,
            },
            });
            
        }else{
            
            notification.open({
            message: 'Server Error During Fetching Overtime Records',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
                background: process.env.VUE_APP_WARNING_COLOR,
            },
            });
        }
        });

    },
  },
  created(){
    this.fetchData();
    this.getEmployee();

   

    this.$store.dispatch('userPermissions', { module: 'incident', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;

        if(response)
        {
            this.columns = [...this.column,{
                title: "Action",
                sorter: false,
                key: "action",
                class: "text-end",
            }];
        }

      })
    })
    this.$store.dispatch('userPermissions', { module: 'incident', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'incident', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })
  }
}
</script>