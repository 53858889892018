import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state() {
    return {
      employees: [],
      companies:[],
      designations:[],
      office:[],
      departments:[],
      all_departments:[],
      employeeProfileData:{},
      employee_search:{
        employee_id:null,
        name:null,
        designation_id:null
      },
      reporters:[],
      assetData:[],
      globalNotifications:[],
      globalNotificationsBadge:0
    };
  },
  mutations: {
    SET_GLOBAL_NOTIFICATION_BADGE(state,globalNotificationsBadge){
      state.globalNotificationsBadge = globalNotificationsBadge < 0 ? 0 : globalNotificationsBadge;
    },
    SET_GLOBAL_NOTIFICATION_DATA_INITIAL(state,notification){
      state.globalNotifications = notification;
    },
    SET_GLOBAL_NOTIFICATION_DATA(state,notification)
    {
      state.globalNotifications.unshift(notification);
    },
    SET_ASSET_DATA(state,asset)
    {
      state.assetData = asset;
    },
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    SET_COMPANY(state, companies) {
      state.companies = companies;
    },
    SET_DESIGNATION(state, designations) {
      state.designations = designations;
    },
	SET_OFFICE(state, office) {
      state.office = office;
    },
    SET_DEPARTMENT(state, departments) {
      state.departments = departments;
    },
    SET_ALL_DEPARTMENT(state, departments){
      state.all_departments = departments;
    },
    SET_EMPLOYEE_PROFILE_DATA(state, employee) {
      state.employeeProfileData = employee;
    },
    SET_EMPLOYEE_SEARCH_FIELD(state,employee_search) {
      state.employee_search = employee_search
    },
    SET_REPORTERS(state,reporter)
    {
      state.reporters = reporter;
    }
  },
  actions: {
    getNotificationDetails({commit})
    {
      try{

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        axios.get('fetch-global-notificaiton').then(response => {

          commit('SET_GLOBAL_NOTIFICATION_DATA_INITIAL', response?.data?.data || []);
          commit('SET_GLOBAL_NOTIFICATION_BADGE', response?.data?.notification_count || 0);
        }).catch(error =>{
          console.log('error',error);
        });

      }
      catch(error){
        console.log(error)
      }
    },
    startPermissionPolling({ commit },payload) {
      setInterval(() => {

        var userDetails = JSON.parse(window.localStorage.getItem('user'),true) || null;
        var token = window.localStorage.getItem("token");

        if(userDetails && userDetails?.id && token)
        {
              const router = payload.router; 
              
              axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
              axios.defaults.headers.common["Authorization"] = "Bearer " + token;

              if(token && userDetails)
              {
                axios.get('employee/'+userDetails?.id+'/check-permission').then(response=>{

                  if(response?.data?.permission?.permissions != userDetails?.permissions?.permissions)
                  {
                    userDetails.permissions.permissions = response?.data?.permission?.permissions;
  
                    localStorage.setItem('user',JSON.stringify(userDetails));
  
                    var user_role = window.localStorage.getItem('role')
  
                    if(user_role && user_role.includes('Employee'))
                    {
                      router.push({name: 'employee-dashboard',params: {hasPermissionChange:true}}).catch(error => {})
                    }
                    else
                    {
                      router.push({name: 'admin-dashboard',params: {hasPermissionChange:true}}).catch(error => {})
                    }
                  }
  
                }).catch(error =>{
                  console.log('error',error);
                });
              }
        }
      },120000)
    },

    userPermissions({commit},{module = null, action = null}){
      var userDetails = JSON.parse(window.localStorage.getItem('user'),true) || null;
     
      var permission =  userDetails ? (JSON.parse(userDetails?.permissions?.permissions,true) || null) : null;
      if (module !== null && action !== null && permission.hasOwnProperty(module) && permission[module].hasOwnProperty(action)) {
        return permission[module][action];
      } else {
          return false; 
      } 
    },
    toggleSidebar1() {
      const body = document.body;
      body.classList.toggle("slide-nav");
    },
    async updateEmployeeSalaryDetails({commit},data){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee/salary-update/'+data?.user_id,data);

        return response;

      }
      catch(error){
        console.log('error during update employee',error);
        return error;
      }
    },
    async fetchPaymentType({commit})
    {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          const response = await axios.get('paymenttypes/all');
          return response?.data?.data;
       
        
      } catch (error) {
        console.error('Error fetching payment type:', error);
      }
    },
    async fetchEmployees({ commit },data = null) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        var query = null;

        if(data != null)
        {
          query = '?name='+data.name+'&employee_id='+data.employee_id+'&designation_id='+data.designation_id;
          const response = await axios.get('employee'+query);
          commit('SET_EMPLOYEES', response.data);
          return response;
        }
        else
        {
          const response = await axios.get('employee');
          commit('SET_EMPLOYEES', response.data);
          return response;
        }

        
      } catch (error) {
        console.error('Error fetching employees:', error);
        return error;
      }
    },

    async fetchAllEmployee({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        const response = await axios.get('employees/all');
        return response?.data?.data || [];

      } catch (error) {
        console.error('Error fetching employees:', error);
        return [];
       
      }
    },

    async fetchCompanies({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('company');

        commit('SET_COMPANY', response.data);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    },
    async fetchDesignations({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('designations/all');
        
        commit('SET_DESIGNATION', (response?.data?.data) ? response.data.data : []);
      } catch (error) {
        console.error('Error fetching designations:', error);
      }
    },
	async fetchOffice({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('office/list');
        
        commit('SET_OFFICE', (response?.data?.data) ? response.data.data : []);
      } catch (error) {
        console.error('Error fetching office:', error);
      }
    },
    async fetchDepartments({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

       await axios.get('departments/all').then(response => {
        const data = response?.data?.data || []
          commit('SET_DEPARTMENT', data);
       }).catch(error => {
          commit('SET_DEPARTMENT', []);
          console.error('Error fetching department:', error);
       });
        
      } catch (error) {
        console.error('Error fetching department:', error);
      }
    },
    async fetchAllDepartments({ commit }) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('departments/all');
        
        commit('SET_ALL_DEPARTMENT', (response?.data?.data) ? response.data.data : []);
      } catch (error) {
        console.error('Error fetching department:', error);
      }
    },
    async fetchEmployeeProfileData({ commit }, user_id) {
      try {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
        const response = await axios.get('employee/' + user_id);
    
        if (response.status === 200) {
          commit('SET_EMPLOYEE_PROFILE_DATA', (response?.data?.data) ? response.data.data : null);
          return response.data.data;
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error fetching department:', error);
        return error;
      }
    },
    async createEmployee({ commit },data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee',data);

        return response;

      }
      catch(error){
        console.log('error during create employee',error.response.data);
        return error.response.data;
      }
        
    },
    async updateEmployee({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.put('employee/'+data.id,data);
        return response;

      }
      catch(error){
        console.log('error during create employee',error.response.data);
        return error.response.data;
      }
    },
    async updateEmployeeProfile({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.put('employee/profile/'+data?.id,data);
        return response;

      }
      catch(error){
        console.log('error during create employee',error.response.data);
        return error.response.data;
      }
    },
    async deleteEmployeeData({commit},id){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.delete('employee/'+id);

       return response;

      }
      catch(error){
       
        return error.response.data;
      }
    },
    setEmployeeData({commit},employee)
    {
      commit('SET_EMPLOYEE_PROFILE_DATA', employee);
    },
    getDateFormate({commit},dateString)
    {
      if(dateString != null)
      {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
      }
      else{
        return null
      }
        
    },
    async updateProfile({commit},formdata) {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee/profile/upload-image/'+formdata.id,formdata.data,{headers: {
          'Content-Type': 'multipart/form-data',
        }});

        return response;
        }catch(error){
          return error.response.data;
        }
    },
    async updatePersonalEmployeeProfile({commit},data) {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.put('employee/profile/personal/'+data?.id,data);

        return response;
        }catch(error){
          return error.response.data;
        }
    },
    async updateEmployeeContact({commit},data){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee/profile/emergency-contact/'+data?.id,data);
        
        return response;
        
      }catch(error){
          return error.response.data;
        }
    },
    async updateEmployeeBankDetail({commit},data){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const payload = {
          IFSC_code: data?.IFSC_code ? data?.IFSC_code : null,
          bank_account_number: data?.bank_account_number ? data?.bank_account_number : null,
          bank_name: data?.bank_name ? data?.bank_name : null,
          pan_number: data?.pan_number ? data?.pan_number : null,
          user_id: data?.user_id ? data?.user_id : null,
          id: data?.id ? data?.id : null
        };
      
        const response = await axios.post('employee/profile/bank-detail/'+data?.user_id,payload);
        
        return response;
        
      }catch(error){
          return error.response.data;
        }
    },
    async updateFamilyMembersDetail({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee/profile/family-information/'+data?.user_id,data);

        return response;
        }catch(error){
          return error.response.data;
        }
    },
    async updateEducationDetail({commit},data){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

        const response = await axios.post('employee/profile/education-information/'+data?.user_id,data,config);

        return response;
        }catch(error){
          return error.response.data;
        }
    },
    async updateExperienceDetail({commit},data){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.post('employee/profile/experience-information/'+data?.user_id,data);

        return response;
        }catch(error){
          return error.response.data;
        }
    },
    async fetchReporters({commit})
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('reporter');
        commit('SET_REPORTERS', (response?.data?.data) ? response.data.data : []);

      }catch(error){
        return error.response.data;
      }
    },
    async deleteFamilyDetails({commit},id)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.delete('employee/profile/family-information/'+id);
        
        return response;

      }catch(error){
        return error.response.data;
      }
    },
    async deleteEducationDetails({commit},id){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.delete('employee/profile/education-information/'+id);
        
        return response;

      }catch(error){
        return error.response.data;
      }
    },
    async deleteExperienceDetails({commit},id){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.delete('employee/profile/experience-information/'+id);
        
        return response;

      }catch(error){
        return error.response.data;
      }
    },
    async getEmployeeAsset({commit},id){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('userasset/'+id+'/employee');
      
        commit('SET_ASSET_DATA',response?.data?.data?.record || []);
        return response?.data?.data?.record;

      }catch(error){
        return error.response.data;
      }
    },
    async getSingleAssetDetails({commit},id){
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('userasset/'+id+'/detail');
       
        return response?.data?.data?.record;

      }catch(error){
        return error.response.data;
      }
    },
    async getEmployeeProject({commit},id)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('project?employee_id='+id);
       
        return response?.data?.data?.data;

      }catch(error){
        return error.response.data;
      }
    },
    async deleteWages({commit},id)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.delete('wages/'+id);

        return response;

      }catch(error){
        return error.response.data;
      }
      
    },
    async updateWages({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if(data?.wages_id && data?.wages_id != null)
        {
          const response = await axios.put('wages/'+data?.wages_id,data);
          return response;
        }
        else
        {
          const response = await axios.post('wages',data);
          return response;
        }



     

      }catch(error){
        return error.response.data;
      }
    },
    async fetchWages({commit},params)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('wages',params);

        return response?.data?.data?.data;

      }catch(error){
        return error.response.data;
      }
    },
    async fetchAllWages({commit})
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('wages/all');

        return response?.data?.data || null;

      }catch(error){
        return error.response.data;
      }
    },
    async fetchEmployeeAttendance({commit})
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('employeeattendance/dashboard');

        return response?.data?.data?.todayactivities;
      }
      catch(error){
        return error.response.data;
      }
    },
    async punchInfortoday({commit},params = {})
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('employeeattendance/punch-in?&user_lat='+params?.user_lat+'&user_lng='+params?.user_lng);

        return response;
      }
      catch(error){
        return error.response.data;
      }
    },

    async punchOutfortoday({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        var params = {
          params: data
        };


        const response = await axios.get('employeeattendance/punch-out',params);

        return response;
      }
      catch(error){
        return error.response.data;
      }
    },

    async attendanceIndex({commit},param)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('employeeattendance',param);

        return response?.data || null;
      }
      catch(error){
        return error.response.data;
      }
    },

    async getShiftList({commit},param)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('shifts/list',param);
        return response?.data?.data|| null;
      } 
      catch(error){
        return error.response;
      }
    },

    async getAllShiftList({commit},shift_id = null)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        const response = await axios.get('shifts/all',{ params:{shift_id:shift_id}});

        return response?.data?.data|| null;
      } 
      catch(error){
        return error.response.data;
      }
    },
    async manageShift({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if(data?.shift_id && data?.shift_id != null)
        {
          const response = await axios.post('shifts/update/'+data?.shift_id,data);
          return response;
        }
        else
        {
          const response = await axios.post('shifts/create',data);
          return response;
        }

      }catch(error){
        return error.response.data;
      }
    },

    async manageSchedule({commit},data)
    {
      try{
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        if(data?.schedule_id && data?.schedule_id != null)
        {
          const response = await axios.post('schedule/update/'+data?.schedule_id,data);
          return response;
        }
        else
        {
          const response = await axios.post('schedule/create',data);
          return response;
        }

      }catch(error){
        return error.response.data;
      }
    },
    
  },

  getters: {
    getGlobalNotification(state)
    {
      return state.globalNotifications;
    },
    getEmployees(state) {
      return state.employees;
    },
    getCompanies(state) {
      return state.companies;
    },
    getDesignations(state) {
      return state.designations;
    },
    getDepartments(state) {
      return state.departments;
    },
	getOffice(state) {
      return state.office;
    },
    getAllDepartments(state) {
      return state.all_departments;
    },
    getEmployeeProfileData(state) {
      return state.employeeProfileData;
    },
    getEMployeeSeatchData(state)
    {
      return state.employee_search;
    },
    getReporter(state)
    {
      return state.reporters
    }
  },
});