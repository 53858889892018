<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header :title="title" :path="path" :text="text" :text1="text1" @addtodayworkpopup="addtodayworkpopup" :hasCreatePermission="hasCreatePermission"/>
        <!-- /Page Header -->
		
		<Form @submit="onSearch">
		 <div class="row filter-row">
			<div class="col-sm-3 col-md-3">
			  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.employeename"
				  @focus="isFocused = true"
				  @blur="isFocused = searchform.employeename !== ''"
				/>
				<label class="focus-label">Employee Name</label>
			  </div>
			</div>
			<div class="col-sm-3 col-md-3">
			  <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.project"
				   @focus="isFocused = true"
				  @blur="isFocused = searchform.project !== ''"
				/>
				<label class="focus-label">Project</label>
			  </div>
			</div>
			<div class="col-sm-3 col-md-3">
			   <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
				<input
				  type="text"
				  class="form-control floating"
				  v-model="searchform.description"
				   @focus="isFocused = true"
				  @blur="isFocused = searchform.description !== ''"
				/>
				<label class="focus-label">Description</label>
			  </div>
			</div>
			<div class="col-sm-3 col-md-3">
			  <div class="d-grid">
				<button class="btn btn-success w-100 submit-btn">Search</button> 
			  </div>
			</div>
		  </div>
		</Form>
		
		<div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries
              </label>
            </div>
            <div class="table-responsive">
		      <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				:pagination="pagination"
				@change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'employee_id'">
                    <h2 class="table-avatar">
                      <router-link :to="{ path: '/profile/'+record?.employee_id}" class="avatar"
                        >
						<img v-bind:src="[record.employee ? record.employee.avatar ? record.employee.avatar : teammemberImg : teammemberImg]" @error="$event.target.src=teammemberImg" alt="User Image"></router-link>
                      <router-link :to="'profile/'+record.employee_id"> {{ record.employee?.first_name }} {{ record.employee?.last_name }}</router-link>
                    </h2>
                  </template>
				  <template v-else-if="column.key === 'project_id'">
                    <div class="text-center" v-if="record.projectdetails">{{ record.projectdetails.project_name }}</div>
                  </template>
                  <template v-else-if="column.key === 'projecthours'">
                    <div class="text-center" v-if="record.projectdetails">{{ record.projectdetails.total_hours }}</div>
                  </template>
                  <template v-else-if="column.key === 'hours'">
                    <div class="text-center">{{ record.hours }}</div>
                  </template>
                  <template v-else-if="column.key === 'Description'">
                    <div class="d-none d-sm-table-cell col-md-4">
                      {{ record.Description }}
                    </div>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <div class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_todaywork"
							@click="EditTimesheet(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_workdetail"
							@click="Deletetime(record.timesheet_id)"
                            ><i class="fa-regular fa-trash-can m-r-5"></i>
                            Delete</a
                          >
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
      <timesheet-model :form="create_form" :projectlist="projectlist" :editform="edit_form" @create-timesheet="createTimesheet" :employeelist="employee_list" :timesheet_id="timesheetid" @delete-timesheet="deleteTimesheet" @update-timesheet="updateTimesheet" ref="timesheetmodel" :selectedmode="selectedmode" @updateprojectlist="updateprojectlist" ></timesheet-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
const columns = [
  {
    title: "Employee",
    dataIndex: "employee_id",
    key: "employee_id",
    sorter: false,
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: false,
  },
  {
    title: "Projects",
    dataIndex: "project_id",
	key: "project_id",
    sorter: false,
  },
  {
    title: "Assigned Hours",
    dataIndex: "hours",
    key: "hours",
    sorter: false,
  },
  {
    title: "Hours",
    dataIndex: "projecthours",
    key: "projecthours",
    sorter: false,
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    class: "d-none d-sm-table-cell col-md-4",
    sorter: false,
  },
//   {
//     title: "Action",
//     sorter: false,
//     key: "action",
//   },
];

const data = [
];

var pagination = {total: 0,current: 1,pageSize: 10};

import axios from 'axios';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";

import moment from 'moment';

export default {
  components: { Form, Field },
  props: {
    teammemberImg: {
      type: String,
      default: require("@/assets/img/profiles/avatar-10.jpg")
    }
  },
  data() {
    return {
	  columns:columns,
      data,
      title: "Timesheet",
      path: "Dashboard",
      text: "Timesheet",
      text1: "Add Today Work",
	  create_form: { "employee_id": "","project_id": "","hours": "", "description" : ""},
	  edit_form: {},
	  projectlist: {},
	  employee_list: {},
	  pagination: pagination,
	  timesheetid : 0, 
	  selectedmode : "",
	  searchform: {"employeename": "","project":"","description": ""},
	  isFocused: false,
	  hasReadPermission:null,
	  hasCreatePermission:null,
	  hasEditPermission:null,
      hasDeletePermission:null
    };
  },
  methods: {
	handleTableChange(pagesize){
		
		var params = {
		   params: { per_page: pagesize.pageSize,page:pagesize.current,employeename: this.searchform.employeename,project:this.searchform.project, description: this.searchform.description }
		};
		
		this.loadCommonData(params);
		
	},
	updateprojectlist(formvals){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		
		var params = {
				   params: { employee_id: formvals.employee_id }
				};
				
		axios.get("/project/all", params)
			.then((response) => {
				
				
				this.projectlist = response.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	onSearch(){
	  
	  var params = {
		   params: { employeename: this.searchform.employeename,project:this.searchform.project, description: this.searchform.description,per_page: this.pagination.pageSize }
		};	
		
		this.loadCommonData(params);
	},
    addtodayworkpopup(){
		
		this.selectedmode = "add";
		
		this.create_form = { "employee_id": "","project_id": "","hours": "", "description" : ""};
		
		this.$refs.timesheetmodel.resetproject();
	},
	deleteTimesheet(timesheetid){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.delete("/timesheet/"+timesheetid, [])
          .then( (response) => {
				
			 loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 
			 this.$refs.timesheetmodel.closeDialog();
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
			 		
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	Deletetime(timesheetid){
		this.timesheetid = timesheetid;
	},
	updateTimesheet(formval){
		
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		var postform = new FormData();		
		postform.append('employee_id',formval.employee_id);
		postform.append('project_id',formval.project_id);
		postform.append('date',moment(formval.date).format('YYYY-MM-DD'));
		postform.append('hours',formval.hours);
		postform.append('description',formval.description);
		postform.append('status',formval.status);
		
		axios.put("/timesheet/"+formval.timesheet_id, postform)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.timesheetmodel.closeDialog();
			 this.edit_form ={};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
			 
			 //this.onSearch();
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	EditTimesheet(record){
		
		var editobj = Object.assign({}, record);
		
		if(editobj.date != ""){
			editobj.date = new Date(editobj.date);
		}
		
		this.edit_form = editobj;
		
		this.selectedmode = "edit";
		
		this.$refs.timesheetmodel.seteditContent();
		
	},
    createTimesheet(formval){
		
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
		
		axios.post("/timesheet", formval)
          .then( (response) => {
				
			  loader.hide();
			  
			  notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_SUCCESS_COLOR,
					},
				});

			 this.$refs.timesheetmodel.closeDialog();
			 
			 this.create_form =  { "project_id": "","hours": "", "description" : ""};
			 
			 var params = {
				   params: { per_page: this.pagination.pageSize }
				};
				
			 this.loadCommonData(params);
					
		}).catch(error => {
          
			 loader.hide();
			 
			if(error.response){
			
				var response = (error.response);
					
				notification.open({
					message: response.data.message,
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
				
			}else{
				
				notification.open({
					message: 'Server Error',
					placement: "topRight",
					duration: process.env.VUE_APP_NOTIFICATION_DURATION,
					style: {
					  background: process.env.VUE_APP_WARNING_COLOR,
					},
				});
			}
			
        });
		
	},
	loadAllEmployee(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/employees/all", params)
			.then((response) => {
				this.employee_list = response?.data?.data || [];
					  
			}).catch((error) => {
			  console.log('error',error);
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
	loadCommonData(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		let loader = this.$loading.show({
						// Optional parameters
						container: this.$refs.formContainer,
						canCancel: false
					});
		
		axios.get("/timesheet", params)
			.then((response) => {
				
				this.data = response.data.data.data;
				
				this.pagination.total = response.data.data.total;
				this.pagination.current = response.data.data.current_page;
				this.pagination.pageSize = response.data.data.per_page;
				
				loader.hide();
					  
			}).catch((error) => {
			  
			  loader.hide();
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
	},
	loadAllProjects(params){
		
		var token = window.localStorage.getItem("token");
	
		axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
		axios.defaults.headers.common["Authorization"] = "Bearer " + token;
		
		axios.get("/project", params)
			.then((response) => {
				
				this.projectlist = response.data.data.data;
					  
			}).catch((error) => {
			  
			  var response = (error.response);
			  
			  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
				
				localStorage.clear();
				
				notification.open({
						message: 'Please Login',
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
					
				this.$router.push({name: 'login'}).catch(error => {}) 
				 
			  }else{
				
				this.errorMessage = error.message;
				notification.open({
						message: response.data.message,
						placement: "topRight",
						duration: process.env.VUE_APP_NOTIFICATION_DURATION,
						style: {
						  background: process.env.VUE_APP_WARNING_COLOR,
						},
					});
				  
			  }
			  
			});
			
	},
  },
  mounted() {

	this.$store.dispatch('userPermissions', { module: 'timing_sheet', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
		if(response)
		{
			this.columns = [...columns,{
				title: "Action",
				sorter: false,
				key: "action",
				class: "text-end",
			}];
		}
      })
    })

	this.$store.dispatch('userPermissions', { module: 'timing_sheet', action: 'create' }).then(response =>{
      this.hasCreatePermission=null;
      this.$nextTick(()=>{
        this.hasCreatePermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'timing_sheet', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'timing_sheet', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

	this.loadCommonData([]);
	
	this.loadAllProjects([]);
	
	this.loadAllEmployee([]);
  }
};
</script>
