<template>
  <!-- Add Client Modal -->
  <div id="add_client" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Client</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
			ref="addclientclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
               
				<div class="input-block mb-3">
                  <label class="col-form-label"
                    >First Name <span class="text-danger">*</span></label
                  >
				   <input v-model="employee.first_name" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors?.first_name && employee_errors?.first_name != null">{{ employee_errors?.first_name[0]  }}</span>
                </div>
			  </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Last Name</label>
                  <input v-model="employee.last_name" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors?.last_name && employee_errors?.last_name != null">{{ employee_errors?.last_name[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Username <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.username" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors?.username && employee_errors?.username != null">{{ employee_errors?.username[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.email" class="form-control" type="email" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.email && employee_errors.email != null">{{ employee_errors?.email[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Password</label>
                  <input v-model="employee.password" class="form-control" type="password" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.password && employee_errors.password != null">{{ employee_errors?.password[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Confirm Password</label>
                  <input v-model="employee.password_confirmation" class="form-control" type="password" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.password_confirmation && employee_errors.password_confirmation != null">{{ employee_errors?.password_confirmation[0]  }}</span>
                </div>
              </div>
			  <!--
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Client ID <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.employee_id" type="text" class="form-control" />
				  <span class="text-danger" v-if="employee_errors && employee_errors?.employee_id && employee_errors?.employee_id != null">{{ employee_errors?.employee_id[0]  }}</span>
                </div>
              </div>-->
			  <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Gender</label>
                    <select  class="form-select form-select-lg" v-model="employee.gender">
                      <option v-for="gndr in genders" :key="gndr.key" :value="gndr.key">{{ gndr?.value }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors?.gender && employee_errors?.gender != null">{{ employee_errors?.gender[0]  }}</span>
                  </div>
                </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Phone </label>
                  <input v-model="employee.contact_number" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.contact_number && employee_errors.contact_number != null">{{ employee_errors?.contact_number[0]  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                  <div class="input-block mb-3">
				    <label class="col-form-label">Company</label>
                    <select v-model="employee.company_id" class="form-select form-select-lg">
                      <option v-for="cmpny in Companies" :key="cmpny.id" :value="cmpny.id">{{ cmpny.company_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.company_id && employee_errors.company_id != null">{{ employee_errors?.company_id[0]  }}</span>

                    <!-- <vue-select
                      :options="Company"
                      placeholder="Global Technologies"
                      id="company"
                      v-model="employee.company_id"
                    /> -->
                  </div>
                </div>
				<div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Client Id </label>
                  <input v-model="employee.client_id" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.client_id && employee_errors.client_id != null">{{ employee_errors?.client_id[0]  }}</span>
                </div>
              </div>
            </div>
            <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <!-- <th class="text-center">Show Navbar</th> -->
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Holidays</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.write"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.create"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.delete"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.import"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.export"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.write"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.create"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.delete"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.import"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.export"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Attendance</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.write"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.create"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.delete"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.import"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.export"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.write"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.create"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.delete"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.import"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.export"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.write"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.create"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.delete"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.import"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.export"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.write"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.create"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.delete"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.import"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.export"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Schedule</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.write"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.create"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.delete"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.import"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.export"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Shift</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.write"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.create"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.delete"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.import"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.export"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.write"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.create"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.delete"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.import"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.export"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Clients</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.write"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.create"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.delete"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.import"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.export"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Projects</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.write"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.create"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.delete"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.import"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.export"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.write"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.create"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.delete"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.import"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.export"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Chats</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.show_navbar" />
                          <span class="checkmark"></span>
                        </label> 
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.write"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.create"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.delete"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.import"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.export"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Assets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.write"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.create"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.delete"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.import"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.export"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.write"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.create"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.delete"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.import"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.export"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Overtime</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.write"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.create"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.delete"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.import"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.export"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Office</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.write"
                            :disabled="!permissions.office.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.create"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.delete"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.import"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.export"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Organization Documents</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.organization_document.read" @change="handlePermissionChangeForRead('organization_document')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.write"
                            :disabled="!permissions.organization_document.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.create"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.delete"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.import"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.export"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click.prevent="addClient">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Client Modal -->

  <!-- Edit Client Modal -->
  <div id="edit_client" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit Client</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-md-6">
			    <div class="input-block mb-3">
                  <label class="col-form-label"
                    >First Name <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.first_name" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.first_name && employee_errors.first_name != null">{{ employee_errors?.first_name[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Last Name</label>
                  <input v-model="employee.last_name" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.last_name && employee_errors.last_name != null">{{ employee_errors?.last_name[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Username <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.username" class="form-control" type="text" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.username && employee_errors.username != null">{{ employee_errors?.username[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Email <span class="text-danger">*</span></label
                  >
                  <input v-model="employee.email" class="form-control" type="email" />
				  <span class="text-danger" v-if="employee_errors && employee_errors.email && employee_errors.email != null">{{ employee_errors?.email[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Password</label>
                  <input v-model="employee.password" class="form-control" type="password" />
                  <span class="text-danger" v-if="employee_errors && employee_errors.password && employee_errors.password != null">{{ employee_errors?.password[0]  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Confirm Password</label>
                  <input v-model="employee.confirm_password" class="form-control" type="password" />
                </div>
              </div>
			  <!--
              <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label"
                    >Client ID <span class="text-danger">*</span></label
                  >
                  <input
                    class="form-control floating"
                    value="CLT-0001"
                    type="text"
                  />
                </div>
              </div> -->
			  <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Gender</label>
                    <select  class="form-select form-select-lg" v-model="employee.gender">
                      <option v-for="gndr in genders" :key="gndr.key" :value="gndr.key">{{ gndr?.value }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors?.gender && employee_errors?.gender != null">{{ employee_errors?.gender[0]  }}</span>
                  </div>
              </div>
              <div class="col-md-6">
                <div class="input-block mb-3">
                  
                   <label class="col-form-label">Phone <span class="text-danger">*</span></label>
                    <input v-model="employee.contact_number" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.contact_number && employee_errors.contact_number != null">{{ employee_errors?.contact_number[0]  }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Company</label>
                    <select v-model="employee.company_id" class="form-select form-select-lg">
                      <option v-for="cmpny in Companies" :key="cmpny.id" :value="cmpny.id">{{ cmpny.company_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.company_id && employee_errors.company_id != null">{{ employee_errors?.company_id[0]  }}</span>
                  </div>
                </div>
            </div>
            <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <!-- <th class="text-center">Show Navbar</th> -->
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Holidays</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.write"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.create"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.delete"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.import"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.export"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.write"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.create"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.delete"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.import"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.export"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Attendance</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.write"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.create"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.delete"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.import"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.export"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.write"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.create"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.delete"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.import"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.export"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.write"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.create"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.delete"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.import"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.export"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.write"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.create"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.delete"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.import"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.export"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Schedule</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.write"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.create"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.delete"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.import"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.export"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Shift</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.write"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.create"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.delete"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.import"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.export"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.write"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.create"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.delete"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.import"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.export"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Clients</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.write"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.create"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.delete"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.import"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.export"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Projects</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.write"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.create"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.delete"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.import"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.export"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.write"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.create"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.delete"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.import"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.export"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Chats</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.show_navbar" />
                          <span class="checkmark"></span>
                        </label> 
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.write"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.create"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.delete"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.import"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.export"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Assets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.write"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.create"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.delete"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.import"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.export"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.write"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.create"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.delete"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.import"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.export"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Overtime</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.write"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.create"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.delete"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.import"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.export"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Office</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.write"
                            :disabled="!permissions.office.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.create"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.delete"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.import"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.export"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Organization Documents</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.organization_document.read" @change="handlePermissionChangeForRead('organization_document')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.write"
                            :disabled="!permissions.organization_document.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.create"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.delete"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.import"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.export"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
			</div>	
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click.prevent="editEmployee">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Client Modal -->

  <!-- Delete Client Modal -->
  <div class="modal custom-modal fade" id="delete_client" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Client</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Client Modal -->
</template>

<script>
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
export default {
	emits: ["refreshClients"],
	components: {
		Form,
		Field,
	},
	props: {
		createform: [Array, Object], 
		Companies: [Array, Object], 
		editform: [Array, Object],
		holiday_id: [Number,String],
		employeeData: {
		  type: Object,
		  required: false,
		  default: () => ({}),
		}		
	},
	data() {
	   return {
			genders:[{
				key:'',
				value:'Select'
			  },{
				key:'f',
				value:'Female'
			  },{
				key:'m',
				value:'Male'
			  },
			  {
				key:'o',
				value:'Others'
			}],
			employee:{
				first_name:null,
				last_name:null,
				username:null,
				email:null,
				password:null,
				confirm_password:null,
				employee_id:null,
				gender:null,
				joining_date_dd:null,
				birth_date_dd:null,
				contact_number:null,
				company_id:null,
				office_id:null,
				department_id:null,
				designation_id:null,
				joining_date:null,
				birth_date:null,
				permission:null,
				office_id:null
			},
			employee_errors: null,
			permissions:{
				holiday: {read: false, write: false,delete: false,create: false,export: false,import: false},
				employee: { read: false,write: false,delete: false,create: false,export: false,import: false},
				department: { read: false,write: false,delete: false,create: false,export: false,import: false},
				designation: {read: false,write: false,delete: false,create: false,export: false,import: false},
				wages: { read: false,write: false,delete: false,create: false,export: false,import: false},
				schedule: {read: false,write: false,delete: false, create: false,export: false,import: false},
				shift: {read: false,write: false, delete: false,create: false, export: false,import: false},
				leave: {read: false,write: false, delete: false,create: false,export: false,import: false},
				client: {read: false,write: false,delete: false, create: false,export: false,import: false},
				project: {read: false,write: false,delete: false,create: false,export: false,import: false},
				task: {read: false,write: false,delete: false,create: false,export: false,import: false},
				chat: {read: false, write: false,delete: false,  create: false,export: false,import: false},
				asset: {read: false,write: false,delete: false,create: false,  export: false,import: false},
				timing_sheet: {read: false,write: false,delete: false,create: false,export: false,import: false},
				overtime: {read: false,write: false,delete: false,create: false,export: false,import: false},
				leave_type: {read: false,write: false,delete: false,create: false,export: false,import: false},
				attendance: {read: false,write: false,delete: false,create: false,export: false,import: false},
				incident: {read: false,write: false,delete: false,create: false,export: false,import: false},
				office: {read: false,write: false,delete: false,create: false,export: false,import: false},
				organization_document:{ read: false,write: false, delete: false,create: false,export: false,import: false }
			},
		}
	},
	methods:{
		handlePermissionChangeForRead(module = null){
		
		  const readPermission = this?.permissions?.[module]?.read;

		  if(!readPermission)
		  {
			this.permissions[module].create = false;
			this.permissions[module].write = false;
			this.permissions[module].delete = false;
			this.permissions[module].import = false;
			this.permissions[module].export = false;
		  }          
		},
		closeDialog(){
			this.$refs.addclientclose.click();
		},
		editEmployee(){
			
			 this.employee_errors = null;
			 this.employee.joining_date = null;
			 this.employee.birth_date = null;
			 this.employee.permission = this.permissions;
			
			if(this.employee.password != null && this.employee.password != this.employee.confirm_password){
			  this.confirm_password_error = 'Password and Confirm Password not matching'
			}

			if(this.employee.password == null || this.employee.password == ''){
			  delete this.employee.password;
			  delete this.employee.confirm_password;
			}
			
			console.log(this.employee);
			var token = window.localStorage.getItem("token");
	
				axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
				axios.defaults.headers.common["Authorization"] = "Bearer " + token;
				
				let loader = this.$loading.show({
								// Optional parameters
								container: this.$refs.formContainer,
								canCancel: false
							});
				
				axios.put("/client/"+this.employee.id, this.employee)
					.then((response) => {
						
						this.closeDialog();
						loader.hide();
						
						this.employee = { first_name:null,last_name:null,username:null,email:null,password:null,confirm_password:null,employee_id:null,gender:null,joining_date_dd:null,birth_date_dd:null,contact_number:null,company_id:null,office_id:null,department_id:null,designation_id:null,joining_date:null,birth_date:null,permission:null,office_id:null};
						
						this.employee_errors = null;
						
						notification.open({
							message: 'Successfully Created',
							placement: "topRight",
							duration: process.env.VUE_APP_NOTIFICATION_DURATION,
							style: {
							  background: process.env.VUE_APP_SUCCESS_COLOR,
							},
						});
						
						
						this.$emit("refreshClients");
							  
					}).catch((error) => {
					  
					  loader.hide();
					  
					  var response = (error.response);
					  
					  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
						
						localStorage.clear();
						
						notification.open({
								message: 'Please Login',
								placement: "topRight",
								duration: process.env.VUE_APP_NOTIFICATION_DURATION,
								style: {
								  background: process.env.VUE_APP_WARNING_COLOR,
								},
							});
							
						this.$router.push({name: 'login'}).catch(error => {}) 
						 
					  }else{
						
						if(response.data?.error && response.data.error != null){
							this.employee_errors = response.data.error;
						}

						this.errorMessage = error.message;
						notification.open({
								message: response.data.message,
								placement: "topRight",
								duration: process.env.VUE_APP_NOTIFICATION_DURATION,
								style: {
								  background: process.env.VUE_APP_WARNING_COLOR,
								},
							});
						  
					  }
					  
				});
		
		
		},
		addClient(){
			
				var token = window.localStorage.getItem("token");
	
				axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
				axios.defaults.headers.common["Authorization"] = "Bearer " + token;
				
				let loader = this.$loading.show({
								// Optional parameters
								container: this.$refs.formContainer,
								canCancel: false
							});
				
				axios.post("/client", this.employee)
					.then((response) => {
						
						this.closeDialog();
						loader.hide();
						
						this.employee = { first_name:null,last_name:null,username:null,email:null,password:null,confirm_password:null,employee_id:null,gender:null,joining_date_dd:null,birth_date_dd:null,contact_number:null,company_id:null,office_id:null,department_id:null,designation_id:null,joining_date:null,birth_date:null,permission:null,office_id:null};
						
						this.employee_errors = null;
						
						notification.open({
							message: 'Successfully Created',
							placement: "topRight",
							duration: process.env.VUE_APP_NOTIFICATION_DURATION,
							style: {
							  background: process.env.VUE_APP_SUCCESS_COLOR,
							},
						});
						
						
						this.$emit("refreshClients");
							  
					}).catch((error) => {
					  
					  loader.hide();
					  
					  var response = (error.response);
					  
					  if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
						
						localStorage.clear();
						
						notification.open({
								message: 'Please Login',
								placement: "topRight",
								duration: process.env.VUE_APP_NOTIFICATION_DURATION,
								style: {
								  background: process.env.VUE_APP_WARNING_COLOR,
								},
							});
							
						this.$router.push({name: 'login'}).catch(error => {}) 
						 
					  }else{
						
						if(response.data?.error && response.data.error != null){
							this.employee_errors = response.data.error;
						}

						this.errorMessage = error.message;
						notification.open({
								message: response.data.message,
								placement: "topRight",
								duration: process.env.VUE_APP_NOTIFICATION_DURATION,
								style: {
								  background: process.env.VUE_APP_WARNING_COLOR,
								},
							});
						  
					  }
					  
				});
			
		}
	},
	watch:{		
		employeeData(newVal){

			if(newVal && newVal != 1){
			
				this.employee = newVal;
				this.employee.joining_date_dd = newVal?.joining_date ? new Date(newVal?.joining_date) : null;
				this.employee.birth_date_dd = newVal?.birth_date != null ? new Date(newVal?.birth_date) : null;
				this.employee.password = null;
				this.employee.confirm_password = null;

				if(newVal && newVal?.user_permission && newVal?.user_permission?.permissions && newVal?.user_permission?.permissions != null){
				  const permission_data = JSON.parse(newVal?.user_permission?.permissions);
				  if(permission_data && permission_data != null){
					this.permissions = permission_data;
				  }
				}
			}
			  
		}	
	}
};
</script>