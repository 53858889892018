<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <!-- Page Content -->
      <div class="content container-fluid">
        <!-- Page Header -->
        <inbox-header @clear-data="clearData" :title="title" :path="path" :text="text" :text1="text1" :hasCreatePermission="hasCreatePermission"  />
        <!-- /Page Header -->

        <!-- Overtime Statistics -->
        <div class="row">
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="stats-info">
              <h6>Overtime Employee</h6>
              <h4> {{ badge?.total_emp }} </h4>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="stats-info">
              <h6>Overtime Hours</h6>
              <h4>{{ badge?.total_ot_hours }} </h4>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="stats-info">
              <h6>Pending Request</h6>
              <h4>{{ badge?.total_pending_request }}</h4>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
            <div class="stats-info">
              <h6>Rejected</h6>
              <h4>{{ badge?.total_rejected }}</h4>
            </div>
          </div>
        </div>
        <!-- /Overtime Statistics -->

        <!-- Filter Section -->
        <div class="row filter-row">
          <div class="col-sm-6 col-md-3">
            <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
              <input
                type="text"
                class="form-control floating"
                v-model="filter.employeeID"
                @focus="isFocused = true"
                @blur="isFocused = employeeID !== ''"
                @keyup.enter="searchOvertime"
              />
              <label class="focus-label">Employee</label>
            </div>
          </div>
          <div class="col-sm-6 col-md-3">
            <div class="input-block mb-3 form-focus" :class="{ focused: isFocused }">
              <select v-model="filter.status" class="form-select form-select-lg"  @change="searchOvertime">
                <option value="">All</option>
                <option value="0">Pending</option>
                <option value="1">Approved</option>
                <option value="2">Rejected</option> 
              </select>
              <label class="focus-label"></label>
            </div>
          </div>
          <div class="col-sm-6 col-md-2">
            <div class="input-block mb-3 form-focus focused">
              <div class="cal-icon">
                <datepicker
                  v-model="filter.startdate"
                  placeholder="Choose Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  @update:model-value="searchOvertime"
                />
              </div>
              
            </div>
          </div>
          <div class="col-sm-6 col-md-2">
            <div class="input-block mb-3 form-focus focused">
              <div class="cal-icon">
                <datepicker
                  v-model="filter.enddate"
                  placeholder="Choose Date"
                  class="form-control floating datetimepicker"
                  :editable="true"
                  :clearable="false"
                  @update:model-value="searchOvertime"
                />
              </div>
              
            </div>
          </div>
          <div class="col-sm-6 col-md-2">
            <a href="javascript:;" class="btn btn-success w-100 submit-btn" @click="searchOvertime"> Search </a>
          </div>
        </div>
        <!-- /Filter Section -->

        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePagination">
                  <option value="3">3</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div class="table-responsive">
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="overtimeData"
                :pagination="pagination"
                @change="handleTableChange"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'Name'">
                    <h2 class="table-avatar blue-link">
                      <router-link :to="'profile/'+record.employee_id" class="avatar"
                        >
                        <img v-if="record?.emp_avatar && record?.emp_avatar != null"
                            :src="getEmployeeAvatar(record?.emp_avatar)"
                            alt="User Image"
                            @error="handleImageErrorEmp(record)"
                        />
                        <img v-else
                          :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                          alt="User Image"
                        />
                    </router-link>
                      <router-link :to="'profile/'+record.employee_id"> {{ record?.emp_first_name }} {{ record?.emp_last_name }}</router-link>
                    </h2>
                  </template>
                  <template v-else-if="column.key === 'status'">
                      <div class="dropdown" v-if="hasEditPermission">
                        <a
                          href="javascript:;"
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          > {{ status[record.status] ? status[record.status] : '-' }}
                        </a>
                        <div class="dropdown-menu">
                          <!-- eslint-disable-next-line vue/no-use-v-if-with-v-for -->
                          <a class="dropdown-item" href="javascript:;" @click="changeStatus(1,record.overtime_id)">
                            <span :class="{'isActive':(status[record.status] == 'Approved' )}">Approved</span>
                          </a>
                          <a class="dropdown-item" href="javascript:;" @click="changeStatus(2,record.overtime_id)">
                            <span :class="{'isActive':(status[record.status] == 'Rejected' )}">Rejected</span>
                          </a>
                          <!-- <a class="dropdown-item" href="javascript:;">Software Tester</a>
                          <a class="dropdown-item" href="javascript:;"
                            >Frontend Developer</a
                          >
                          <a class="dropdown-item" href="javascript:;">UI/UX Developer</a> -->
                        </div>
                      </div>
                      <div v-else>
                        <a
                          href="javascript:;"
                          class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          @click="notAuthorized"
                          > {{ status[record.status] ? status[record.status] : '-' }}
                        </a>
                      </div>
                    <!-- <div class="text-center">
                      <div class="action-label">
                        <a
                          class="btn btn-white btn-sm btn-rounded"
                          href="javascript:void(0);"
                        >
                          <i class="fa-regular fa-circle-dot text-purple"></i>
                          {{ status[record.status] ? status[record.status] : '-' }}
                        </a>
                      </div>
                    </div> -->
                  </template>
                  
                  <template v-else-if="column.key === 'approved_by'">
                    <h2 class="table-avatar" v-if="record?.approved_by">
                        <router-link :to="'profile/'+record.approved_by" class="avatar"
                          >
                          <img v-if="record?.apprv_avatar && record?.apprv_avatar != null"
                              :src="getEmployeeAvatar(record?.apprv_avatar)"
                              alt="User Image"
                              @error="handleImageErrorApprv(record)"
                          />
                          <img v-else
                            :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                            alt="User Image"
                          />
                      </router-link>
                      <router-link :to="'profile/'+record.approved_by"> {{ record?.apprv_first_name }} {{ record?.apprv_last_name }}</router-link>
                    </h2>
                    <p class="text-center"  v-else>
                      Not Approved
                    </p>
                  </template>
                  <template v-if="column.key === 'overtime_type'">
                    <p>{{ record?.overtime_type }}</p>
                  </template>
                  <template v-else-if="column.key === 'action'">
                    <td class="text-end" v-if="hasEditPermission || hasDeletePermission">
                      <div class="dropdown dropdown-action">
                        <a 
                          href="javascript:;"
                          class="action-icon dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          ><i class="material-icons">more_vert</i></a
                        >
                        <div class="dropdown-menu dropdown-menu-right">
                          <a v-if="hasEditPermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#add_overtime"
                            @click="updateOvertimeM(record)"
                            ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                          >
                          <a v-if="hasDeletePermission"
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#delete_overtime"
                            @click="delete_id = record.overtime_id"
                            ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                          >
                        </div>
                      </div>
                    </td>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->

      <overtime-model @reload-data="fetchOtData(null,null)" :updateOvertime="updateOvertime" @delete-record="deleteOvertime" :closeModal="closeModal"></overtime-model>
    </div>
    <!-- /Page Wrapper -->
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { notification } from "ant-design-vue";
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};

const columns = [
  {
    title: "#",
    dataIndex: "id",
    customRender: ({ index }) => {
		  return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a?.emp_first_name?.toLowerCase();
        b = b?.emp_first_name?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "OT Date",
    dataIndex: "date",
    key: "date",
    sorter: {
      compare: (a, b) => {
        a = a?.date?.toLowerCase();
        b = b?.date?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "OT Hours",
    dataIndex: "hours",
    key:'hours',
    sorter: {
      compare: (a, b) => {
        a = a?.hours;
        b = b?.hours;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "OT Type",
    dataIndex: "overtime_type",
    key:'overtime_type',
    sorter: {
      compare: (a, b) => {
        a = a?.overtime_type;
        b = b?.overtime_type;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key:'description',
    sorter: {
      compare: (a, b) => {
        a = a?.description?.toLowerCase();
        b = b?.description?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: {
      compare: (a, b) => {
        a = a?.status?.toLowerCase();
        b = b?.status?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Approved by",
    dataIndex: "approved_by",
    key: "approved_by",
    sorter: {
      compare: (a, b) => {
        a = a?.apprv_first_name?.toLowerCase();
        b = b?.apprv_first_name?.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  }
];
export default {
  data() {
    return {
      filter:{
        status:'',
        startdate:new Date(moment().startOf('month')),
        enddate:new Date(moment().endOf('month')),
        employeeID:null
      },  
      isFocused: false,
      title: "Overtime",
      path: "Dashboard",
      text: "Overtime",
      text1: "Add Overtime",
      columns:columns,
      perpage: 10,
      pagination: pagination,
      overtimeData:[],
      status:[
        "Pending",
        "Approved",
        "Rejected"
      ],
      employees:[],
      updateOvertime:null,
      closeModal:false,
      badge:{
        total_emp:0,
        total_ot_hours:0,
        total_pending_request:0,
        total_rejected:0
      },
      hasReadPermission:null,
      hasCreatePermission:null,
      hasEditPermission:null,
      hasDeletePermission:null
    };
  },
  methods:{
    searchOvertime()
    {
      this.fetchOtData(null,this.filter);
    },
    addFocusClass() {
      this.isFocused = true;
    },
    removeFocusClass() {
      this.isFocused = false;
    },
    notAuthorized()
    {
      notification.open({
        message: 'You are not authorized to update overtime status for this employee',
        placement: "topRight",
        duration: process.env.VUE_APP_NOTIFICATION_DURATION,
        style: {
          background: process.env.VUE_APP_WARNING_COLOR,
        },
      });
    },
    async changeStatus(status = null, overtime_id = null)
    {
      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      if(status && overtime_id)
      {
        await axios.post('/overtime/update-status',{'status':status,'overtime_id':overtime_id}).then(response=>{
          
          if(response?.status == 200)
           {
              notification.open({
                message: response?.data?.message || 'Successful',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_SUCCESS_COLOR,
                },
              });
           }

           this.fetchOtData(null,null);

        }).catch(error => {
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }
          
          if(error.response){
          
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error During Fetching Overtime Records',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
        })
      }
    },
    clearData()
    {
      this.updateOvertime = {
        employee_id:'',
        date:null,
        hours:null,
        description:'',
        overtime_id:null
      }
    },
    updatePagination()
    {
      this.fetchOtData(null,null);
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.fetchOtData(params,null);
    },
    async fetchOtData(params = null,search = null)
    {
        var token = window.localStorage.getItem("token");
	
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var param = null

        if(params != null)
        {
          param = params
        }
        else
        {
          param = {
            params: { per_page: this.pagination.pageSize }
          };
        }
        var search_data = {};
        if(search != null)
        {
          if(search?.employeeID != null)
          {
            search_data.employee_id = search?.employeeID
          }

          if(search?.startdate != null)
          {
            search_data.startdate = moment(search?.startdate).format('YYYY-MM-DD')
          }
          else
          {
            search_data.startdate = moment().startOf('month').format('YYYY-MM-DD')
          }

          if(search?.enddate != null)
          {
            search_data.enddate = moment(search?.enddate).format('YYYY-MM-DD')
          }
          else
          {
            search_data.enddate = moment().endOf('month').format('YYYY-MM-DD')
          }

          if(search?.status != null)
          {
            search_data.status = search?.status
          }

        }
        else
        {
          search_data.startdate = moment().startOf('month').format('YYYY-MM-DD')
          search_data.enddate = moment().endOf('month').format('YYYY-MM-DD')
        }

        if(search_data?.employee_id != null || search_data?.startdate != null || search_data?.enddate != null || search_data?.status != null)
        {
          param.params = Object.assign({},param.params,search_data);
        }

        await axios.get('/overtime',param).then(response => {

          loader.hide();

          this.overtimeData = response?.data?.data?.data ? response?.data?.data?.data : []


          const data = response?.data?.dashboard;
          
          this.badge = {
            total_emp:data?.total_emp || 0,
            total_ot_hours:data?.total_ot_hours || 0,
            total_pending_request:data?.total_pending_request || 0,
            total_rejected:data?.total_rejected || 0
          }

          this.pagination.total = response?.data?.data?.total;
          this.pagination.current = response?.data?.data?.current_page;
          this.pagination.pageSize = response?.data?.data?.per_page;

        }).catch(error => {

          loader.hide();  
          
          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }
          
          if(error.response){
          
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error During Fetching Overtime Records',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
          
        });
    },
    async deleteOvertime()
    {
      this.closeModal = false;

      if(this.delete_id != null)
      {
        var token = window.localStorage.getItem("token");
	
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        await axios.delete('/overtime/'+this.delete_id).then(response => {
           if(response?.status == 200)
           {
              notification.open({
                message: response?.data?.message || 'Successful',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_SUCCESS_COLOR,
                },
              });
           }

           this.fetchOtData(null,null);

           this.closeModal = true;

           this.delete_id = null;

        }).catch(error => {

          if(error.response.status == 401 && response.data.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }

          if(error.response){

            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error ',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }

          });
      }
      this.delete_id = null
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageErrorEmp(record){
      record.emp_avatar = null;
      record.emp_avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    handleImageErrorApprv(record){
      record.apprv_avatar = null;
      record.apprv_avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    updateOvertimeM(record = null)
    {
      this.updateOvertime = record;
    },
  },
  created()
  {

    this.$store.dispatch('userPermissions', { module: 'overtime', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
        if(response)
        {
          this.columns = [...columns,{
            title: "Action",
            sorter: false,
            key: "action",
            class: "text-end",
          }];
        }
      })
    })

    this.$store.dispatch('userPermissions', { module: 'overtime', action: 'create' }).then(response =>{
      this.hasCreatePermission=null;
      this.$nextTick(()=>{
        this.hasCreatePermission = response;
      })
    })


    this.$store.dispatch('userPermissions', { module: 'overtime', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'overtime', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

    this.fetchOtData(null,null);
  }
};
</script>
