<template>
  <!-- Add Asset Modal -->
  <div id="add_asset" class="modal custom-modal fade" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ assetForm?.asset_id ? 'Edit' : 'Add' }} Asset</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="asset_modal_close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Asset Name</label>
                  <input class="form-control"  type="text" v-model="assetForm.name" @change="handleChangeEvent('name')" :class="{ 'is-invalid': clientError.includes('name') }" />
                  <span class="text-danger" v-if="errors && errors?.name && errors?.name != null">{{ errors?.name[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Asset Id</label>
                  <input class="form-control" type="text" v-model="assetForm.uuid" @change="handleChangeEvent('uuid')" :class="{ 'is-invalid': clientError.includes('uuid') }" />
                  <span class="text-danger" v-if="errors && errors?.uuid && errors?.uuid != null">{{ errors?.uuid[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Purchase Date</label>
                  <datepicker
                    v-model="startdate"
                    class="form-control floating datetimepicker"
                    :editable="true"
                    :clearable="false"
                    :input-format="dateFormat"
                    @change="handleChangeEvent('purchase_date')" 
                    :class="{ 'is-invalid': clientError.includes('purchase_date') }"
                  />
                  <span class="text-danger" v-if="errors && errors?.purchase_date && errors?.purchase_date != null">{{ errors?.purchase_date[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Purchase From</label>
                  <input class="form-control" type="text"  v-model="assetForm.purchase_from" @change="handleChangeEvent('purchase_from')"  :class="{ 'is-invalid': clientError.includes('purchase_from') }" />
                  <span class="text-danger" v-if="errors && errors?.purchase_from && errors?.purchase_from != null">{{ errors?.purchase_from[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Manufacturer</label>
                  <input class="form-control" type="text" v-model="assetForm.manufacturer" @change="handleChangeEvent('manufacturer')" :class="{ 'is-invalid': clientError.includes('manufacturer') }" />
                  <span class="text-danger" v-if="errors && errors?.manufacturer && errors?.manufacturer != null">{{ errors?.manufacturer[0]  }}</span>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Model</label>
                  <input class="form-control" type="text" v-model="assetForm.model" @change="handleChangeEvent('model')" :class="{ 'is-invalid': clientError.includes('model') }" />
                  <span class="text-danger" v-if="errors && errors?.model && errors?.model != null">{{ errors?.model[0]  }}</span>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Type</label>
                  <input class="form-control" type="text" v-model="assetForm.type" @change="handleChangeEvent('type')" :class="{ 'is-invalid': clientError.includes('type') }" />
                  <span class="text-danger" v-if="errors && errors?.type && errors?.type != null">{{ errors?.type[0]  }}</span>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Brand</label>
                  <input class="form-control" type="text" v-model="assetForm.brand" @change="handleChangeEvent('brand')" :class="{ 'is-invalid': clientError.includes('brand') }" />
                  <span class="text-danger" v-if="errors && errors?.brand && errors?.brand != null">{{ errors?.brand[0]  }}</span>

                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Serial Number</label>
                  <input class="form-control" type="text" v-model="assetForm.serial_number" @change="handleChangeEvent('serial_number')" :class="{ 'is-invalid': clientError.includes('serial_number') }" />
                  <span class="text-danger" v-if="errors && errors?.serial_number && errors?.serial_number != null">{{ errors?.serial_number[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Supplier</label>
                  <input class="form-control" type="text" v-model="assetForm.supplier" @change="handleChangeEvent('supplier')" :class="{ 'is-invalid': clientError.includes('supplier') }" />
                  <span class="text-danger" v-if="errors && errors?.supplier && errors?.supplier != null">{{ errors?.supplier[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Condition</label>
                  <input class="form-control" type="text" v-model="assetForm.condition" @change="handleChangeEvent('condition')" :class="{ 'is-invalid': clientError.includes('condition') }" />
                  <span class="text-danger" v-if="errors && errors?.condition && errors?.condition != null">{{ errors?.condition[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Warranty</label>
                  <input
                    class="form-control"
                    type="number"
                    placeholder="In Months"
                    v-model="assetForm.warranty"
                    @change="handleChangeEvent('warranty')"
                    :class="{ 'is-invalid': clientError.includes('warranty') }" />
                  <span class="text-danger" v-if="errors && errors?.warranty && errors?.warranty != null">{{ errors?.warranty[0]  }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="input-block mb-3">
                  <label class="col-form-label">Value</label>
                  <input placeholder="$" @change="handleChangeEvent('value')" class="form-control" type="number" v-model="assetForm.value"  :class="{ 'is-invalid': clientError.includes('value') }" />
                  <span class="text-danger" v-if="errors && errors?.value && errors?.value != null">{{ errors?.value[0]  }}</span>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Asset User</label>
                  <select
                    v-model="assetForm.asset_user_id"
                    @change="handleChangeEvent('asset_user_id')"
                    :class="{ 'is-invalid': clientError.includes('asset_user_id') }"
                    class="form-select form-select-lg"
                  >
                    <option v-for="emp in employees" :key="'emp'+emp.id" :value="emp.id">
                      {{ emp.first_name }} {{ emp.last_name }}
                    </option>
                  </select>
                  <span class="text-danger" v-if="errors && errors?.asset_user_id && errors?.asset_user_id != null">{{ errors?.asset_user_id[0]  }}</span>
                </div>
              </div> -->
              
              <!-- <div class="col-md-6">
                <div class="input-block mb-3">
                  <label class="col-form-label">Status</label>
                  <select
                    v-model="assetForm.status"
                    @change="handleChangeEvent('status')"
                    :class="{ 'is-invalid': clientError.includes('status') }"
                    class="form-select form-select-lg"
                    >
                    <option v-for="(st,index) in addassetstatus" :key="'status_'+st" :value="index">
                      {{ st }}
                    </option>
                  </select>
                  <span class="text-danger" v-if="errors && errors?.status && errors?.status != null">{{ errors?.status[0]  }}</span>

                </div>
              </div> -->
              <div class="col-md-8">
                <div class="input-block mb-3">
                  <label class="col-form-label">Description</label>
                  <textarea class="form-control" @change="handleChangeEvent('description')" v-model="assetForm.description" :class="{ 'is-invalid': clientError.includes('description') }"></textarea>
                  <span class="text-danger" v-if="errors && errors?.description && errors?.description != null">{{ errors?.description[0]  }}</span>

                </div>
              </div>
              <div class="col-md-12">
                <input
                    type="file"
                    class="form-control"
                    data-buttontext="Choose File"
                    data-icon="false"
                    data-classbutton="btn btn-default"
                    data-classinput="form-control inline input-s"
                    name="documents[]"
                    multiple
                    accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                    ref="attachments" 
                />
              </div>
            </div>
            <div class="submit-section">
              <button class="btn btn-primary submit-btn" @click="onSubmitForm">Submit</button>
            </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Asset Modal -->

  <!-- Delete Asset Modal -->
  <div class="modal custom-modal fade" id="delete_asset" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Asset</h3>
            <p>Are you sure want to delete?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  class="btn btn-primary continue-btn"
                  @click="$emit('delete-asset')"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:void(0);"
                  data-bs-dismiss="modal"
                  ref="close_asset_delete_modal"
                  class="btn btn-primary cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Asset Modal -->
</template>
<script>
import axios from "axios";
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import { notification } from "ant-design-vue";
export default {
  emits:['reloadAssetData','delete-asset'],
  props:{
    assetClear: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    closeDeleteModal:{
      type: Boolean,
      required: false,
      default: () => false,
    },
    editData:{
      type: Object,
      required: false,
      default: () => null,
    }
  },
  data() {
    return {
      select2: null,
      addassetsuser: ["John Doe", "Richard Miles"],
      addassetstatus: ["Pending", "Approved", "Deployed", "Damaged"],
      editassetsuser: ["John Doe", "Richard Miles"],
      editassetstatus: ["Pending", "Approved", "Deployed", "Damaged"],
      startdate: currentDate,
      dateFormat: 'dd-MM-yyyy',
      startdateone: currentDateOne,
      assetForm:{
        asset_id:null,
        uuid:'',
        name:'',
        purchase_date:'',
        purchase_from:'',
        manufacturer:'',
        model:'',
        type:'',
        brand:'',
        serial_number:'',
        supplier:'',
        condition:'',
        warranty:'',
        value:'',
        description:'',
        status:0,
      },
      clientError:[],
      errors:[],
      // employees:[]
    };
  },
  watch:{
    assetClear(newVal)
    {
      if(newVal)
      {
        this.clearForm();
        const randomNumber = Math.floor(100000 + Math.random() * 900000);
        this.assetForm.uuid = 'AST-'+randomNumber;
      }
    },
    closeDeleteModal(newVal)
    {
      if(newVal)
      {
        this.$refs.close_asset_delete_modal.click();
      }
    },
    editData(newVal)
    {
      if(newVal?.asset_id)
      {
        this.assetForm = { ...newVal };
        this.startdate = (newVal?.purchase_date) ? new Date(newVal?.purchase_date) : null;
      }
    }
  },
  methods:{
    handleChangeEvent(target)
    {
      this.clientError = this.clientError.filter(item => item !== target);
    },
    clearForm()
    {
      this.assetForm = {
        asset_id:null,
        uuid:'',
        name:'',
        purchase_date:'',
        purchase_from:'',
        manufacturer:'',
        model:'',
        type:'',
        brand:'',
        serial_number:'',
        supplier:'',
        condition:'',
        warranty:'',
        value:'',
        description:'',
        status:0,
      }
    },
    async onSubmitForm()
    {
      this.clientError = [];
      this.errors = [];

      if(this.assetForm?.uuid == '')
      {
        this.clientError.push('uuid');
      }
      if(this.assetForm?.name == '')
      {
        this.clientError.push('name');
      }

      if (!isNaN(this.startdate.getTime())) {
        var dd = this.startdate;
        dd.setMonth(dd.getMonth() + 1);
        const year = dd.getFullYear();
        const month = dd.getMonth().toString().padStart(2, '0');
        const day = dd.getDate().toString().padStart(2, '0');
        this.assetForm.purchase_date = `${year}-${month}-${day}`;
      }
      else
      {
        this.clientError.push('purchase_date');
      }
      if(this.assetForm?.manufacturer == '')
      {
        this.clientError.push('manufacturer');
      }
      if(this.assetForm?.value == '')
      {
        this.clientError.push('value');
      }

      if(this.assetForm?.type == '')
      {
        this.clientError.push('type');
      }

      if(this.assetForm?.brand == '')
      {
        this.clientError.push('brand');
      }
      

      if(!this.clientError?.length)
      {

       
        var formData = new FormData();

        for (var key in this.assetForm) {
          if (this.assetForm.hasOwnProperty(key)) {
            formData.append(key,this.assetForm[key]);
          }
        }

        const filesInput = this.$refs.attachments;
            for (let i = 0; i < filesInput.files.length; i++) {
            formData.append('documents[]', filesInput.files[i]);
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        };

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        let Request = null;

        if(this.assetForm?.asset_id != null)
        {
          Request = axios.post('/asset/'+this.assetForm?.asset_id,formData,config);
        }
        else
        {
          Request = axios.post('/asset',formData,config);
        }

        Request.then((response)=>{
          this.disabledButton = false

          if(response.status == 200)
          {
            this.$refs.attachments.value = '';
            this.$emit('reload-asset-data');
            this.$refs.asset_modal_close.click();
            notification.open({
              message: response?.data?.message || 'Successful',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
          }
        }).catch((error)=>{
          this.disabledButton = false
          if(error.response){
      
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          
            this.errors = response?.data?.error
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
          

        });
      }
      else{
        this.disabledButton = false
      }
    },
    // async getEmployee(){

    //   var token = window.localStorage.getItem("token");
	
    //   axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    //   axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    //   await axios.get('/employees/all').then(response=>{

    //     this.employees = response?.data?.data ? response?.data?.data : []
    //   }).catch(error => {
    //     if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
    //         localStorage.clear();
            
    //         notification.open({
    //             message: 'Please Login',
    //             placement: "topRight",
    //             duration: process.env.VUE_APP_NOTIFICATION_DURATION,
    //             style: {
    //               background: process.env.VUE_APP_WARNING_COLOR,
    //             },
    //           });

            
    //         this.$router.push({name: 'login'}).catch(error => {}) 
    //     }
        
    //     if(error?.response){
        
    //       var response = (error.response);
            
    //       notification.open({
    //         message: response.data.message,
    //         placement: "topRight",
    //         duration: process.env.VUE_APP_NOTIFICATION_DURATION,
    //         style: {
    //           background: process.env.VUE_APP_WARNING_COLOR,
    //         },
    //       });
          
    //     }else{
          
    //       notification.open({
    //         message: 'Server Error During Fetching Overtime Records',
    //         placement: "topRight",
    //         duration: process.env.VUE_APP_NOTIFICATION_DURATION,
    //         style: {
    //           background: process.env.VUE_APP_WARNING_COLOR,
    //         },
    //       });
    //     }
    //   });

    // }
  },
  created(){
    // this.getEmployee();
  }
 
};
</script>
<style scoped>
select.form-select-lg {
    padding-top: 0.9rem !important;
    padding-bottom: 0.2rem !important;
    font-size:1rem !important
}
</style>