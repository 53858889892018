<template>
  <div ref="formContainer">
    <!-- Add Employee Modal -->
    <div id="add_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Employee</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="add_employee_close"
              @click="employee_errors = null"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.first_name" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors?.first_name && employee_errors?.first_name != null">{{ employee_errors?.first_name[0]  }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Last Name</label>
                    <input v-model="employee.last_name" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors?.last_name && employee_errors?.last_name != null">{{ employee_errors?.last_name[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.username" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors?.username && employee_errors?.username != null">{{ employee_errors?.username[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.email" class="form-control" type="email" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.email && employee_errors.email != null">{{ employee_errors?.email[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Password</label>
                    <input v-model="employee.password" class="form-control" type="password" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.password && employee_errors.password != null">{{ employee_errors?.password[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Confirm Password</label>
                    <input v-model="employee.confirm_password" class="form-control" type="password" />
                    <span class="text-danger" v-if="confirm_password_error &&  confirm_password_error != null">{{ confirm_password_error  }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Employee ID <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.employee_id" type="text" class="form-control" />
                    <span class="text-danger" v-if="employee_errors && employee_errors?.employee_id && employee_errors?.employee_id != null">{{ employee_errors?.employee_id[0]  }}</span>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Gender</label>
                    <select  class="form-select form-select-lg" v-model="employee.gender">
                      <option v-for="gndr in genders" :key="gndr.key" :value="gndr.key">{{ gndr?.value }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors?.gender && employee_errors?.gender != null">{{ employee_errors?.gender[0]  }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <datepicker
                        v-model="employee.joining_date_dd"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                    <span class="text-danger" v-if="employee_errors && employee_errors.joining_date && employee_errors.joining_date != null">{{ employee_errors?.joining_date[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Birth Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <datepicker
                        v-model="employee.birth_date_dd"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                    <span class="text-danger" v-if="employee_errors && employee_errors.birth_date && employee_errors.birth_date != null">{{ employee_errors?.birth_date[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Phone <span class="text-danger">*</span></label>
                    <input v-model="employee.contact_number" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.contact_number && employee_errors.contact_number != null">{{ employee_errors?.contact_number[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Company</label>
                    <select v-model="employee.company_id" class="form-select form-select-lg">
                      <option v-for="cmpny in Companies" :key="cmpny.id" :value="cmpny.id">{{ cmpny.company_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.company_id && employee_errors.company_id != null">{{ employee_errors?.company_id[0]  }}</span>

                    <!-- <vue-select
                      :options="Company"
                      placeholder="Global Technologies"
                      id="company"
                      v-model="employee.company_id"
                    /> -->
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Department <span class="text-danger">*</span></label
                    >
                    <select v-model="employee.department_id" class="form-select form-select-lg">
                      <option v-for="dprtmnt in Departments" :key="dprtmnt.id" :value="dprtmnt.id" >{{ dprtmnt.name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.department_id && employee_errors.department_id != null">{{ employee_errors?.department_id[0]  }}</span>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Designation <span class="text-danger">*</span></label
                    >
                    <select v-model="employee.designation_id" class="form-select form-select-lg" @change="designation_change(employee.designation_id)">
                      <option v-for="desgntn in Designations" :key="desgntn.id" :value="desgntn.id">{{ desgntn.name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.designation_id && employee_errors.designation_id != null">{{ employee_errors?.designation_id[0]  }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Office <span class="text-danger">*</span> </label
                    >
                    <select v-model="employee.office_id" class="form-select form-select-lg">
                      <option v-for="ofc in offices" :key="ofc.office_id" :value="ofc.office_id">{{ ofc.office_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.office_id && employee_errors.office_id != null">{{ employee_errors?.office_id[0]  }}</span>
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <!-- <th class="text-center">Show Navbar</th> -->
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Holidays</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.write"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.create"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.delete"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.import"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.export"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.write"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.create"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.delete"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.import"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.export"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Attendance</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.write"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.create"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.delete"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.import"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.export"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.write"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.create"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.delete"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.import"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.export"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.write"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.create"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.delete"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.import"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.export"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.write"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.create"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.delete"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.import"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.export"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Schedule</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.write"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.create"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.delete"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.import"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.export"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Shift</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.write"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.create"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.delete"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.import"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.export"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.write"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.create"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.delete"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.import"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.export"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Clients</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.write"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.create"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.delete"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.import"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.export"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Projects</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.write"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.create"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.delete"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.import"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.export"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.write"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.create"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.delete"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.import"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.export"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Chats</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.show_navbar" />
                          <span class="checkmark"></span>
                        </label> 
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.write"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.create"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.delete"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.import"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.export"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Assets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.write"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.create"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.delete"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.import"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.export"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.write"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.create"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.delete"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.import"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.export"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Overtime</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.write"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.create"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.delete"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.import"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.export"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Office</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.write"
                            :disabled="!permissions.office.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.create"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.delete"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.import"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.export"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Organization Documents</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.organization_document.read" @change="handlePermissionChangeForRead('organization_document')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.write"
                            :disabled="!permissions.organization_document.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.create"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.delete"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.import"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.export"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn" :disabled="disableButton" @click.prevent="addEmployee">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Add Employee Modal -->

    <!-- Edit Employee Modal -->
    <div id="edit_employee" class="modal custom-modal fade" role="dialog">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Employee</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref="update_employee_close"
              @click="employee_errors = null"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.first_name" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.first_name && employee_errors.first_name != null">{{ employee_errors?.first_name[0]  }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Last Name</label>
                    <input v-model="employee.last_name" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.last_name && employee_errors.last_name != null">{{ employee_errors?.last_name[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.username" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.username && employee_errors.username != null">{{ employee_errors?.username[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.email" class="form-control" type="email" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.email && employee_errors.email != null">{{ employee_errors?.email[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Password</label>
                    <input v-model="employee.password" class="form-control" type="password" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.password && employee_errors.password != null">{{ employee_errors?.password[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Confirm Password</label>
                    <input v-model="employee.confirm_password" class="form-control" type="password" />
                    
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Employee ID <span class="text-danger">*</span></label
                    >
                    <input v-model="employee.employee_id" type="text" class="form-control" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.employee_id && employee_errors.employee_id != null">{{ employee_errors?.employee_id[0]  }}</span>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Gender</label>
                    <select  class="form-select form-select-lg" v-model="employee.gender">
                      <option v-for="gndr in genders" :key="gndr.key" :value="gndr.key">{{ gndr?.value }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors?.gender && employee_errors?.gender != null">{{ employee_errors?.gender[0]  }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Joining Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <datepicker
                        v-model="employee.joining_date_dd"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                    <span class="text-danger" v-if="employee_errors && employee_errors.joining_date && employee_errors.joining_date != null">{{ employee_errors?.joining_date[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Birth Date <span class="text-danger">*</span></label
                    >
                    <div class="cal-icon">
                      <datepicker
                        v-model="employee.birth_date_dd"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                        :input-format="dateFormat"
                      />
                    </div>
                    <span class="text-danger" v-if="employee_errors && employee_errors.birth_date && employee_errors.birth_date != null">{{ employee_errors?.birth_date[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Phone <span class="text-danger">*</span></label>
                    <input v-model="employee.contact_number" class="form-control" type="text" />
                    <span class="text-danger" v-if="employee_errors && employee_errors.contact_number && employee_errors.contact_number != null">{{ employee_errors?.contact_number[0]  }}</span>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label">Company</label>
                    <select v-model="employee.company_id" class="form-select form-select-lg">
                      <option v-for="cmpny in Companies" :key="cmpny.id" :value="cmpny.id">{{ cmpny.company_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.company_id && employee_errors.company_id != null">{{ employee_errors?.company_id[0]  }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Department <span class="text-danger">*</span></label
                    >
                    <select v-model="employee.department_id" class="form-select form-select-lg">
                      <option v-for="dprtmnt in Departments" :key="dprtmnt.id" :value="dprtmnt.id">{{ dprtmnt.name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.department_id && employee_errors.department_id != null">{{ employee_errors?.department_id[0]  }}</span>

                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Designation <span class="text-danger">*</span></label
                    >
                    <select v-model="employee.designation_id" class="form-select form-select-lg" @change="designation_change(employee.designation_id)" >
                      <option v-for="desgntn in Designations" :key="desgntn.id" :value="desgntn.id">{{ desgntn.name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.designation_id && employee_errors.designation_id != null">{{ employee_errors?.designation_id[0]  }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block mb-3">
                    <label class="col-form-label"
                      >Office  <span class="text-danger">*</span></label
                    >
                    <select v-model="employee.office_id" class="form-select form-select-lg">
                      <option v-for="ofc in offices" :key="ofc.office_id" :value="ofc.office_id">{{ ofc.office_name }}</option>
                    </select>
                    <span class="text-danger" v-if="employee_errors && employee_errors.office_id && employee_errors.office_id != null">{{ employee_errors?.office_id[0]  }}</span>
                  </div>
                </div>
              </div>
              <div class="table-responsive m-t-15">
                <table class="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <!-- <th class="text-center">Show Navbar</th> -->
                      <th class="text-center">Read</th>
                      <th class="text-center">Write</th>
                      <th class="text-center">Create</th>
                      <th class="text-center">Delete</th>
                      <th class="text-center">Import</th>
                      <th class="text-center">Export</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Holidays</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.holiday.read" @change="handlePermissionChangeForRead('holiday')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.write"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.create"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.delete"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.import"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.holiday.export"
                            :disabled="!permissions.holiday.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Employee</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.employee.read" @change="handlePermissionChangeForRead('employee')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.write"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.create"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.delete"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.import"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.employee.export"
                            :disabled="!permissions.employee.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Attendance</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox"
                          name="rememberme"
                          class="rememberme" v-model="permissions.attendance.read"  @change="handlePermissionChangeForRead('attendance')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.write"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.create"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.delete"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.import"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.attendance.export"
                            :disabled="!permissions.attendance.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Department</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.department.read" @change="handlePermissionChangeForRead('department')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.write"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.create"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.delete"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.import"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.department.export"
                            :disabled="!permissions.department.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Designation</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.designation.read" @change="handlePermissionChangeForRead('designation')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.write"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.create"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.delete"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.import"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.designation.export"
                            :disabled="!permissions.designation.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Wages</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.wages.read" @change="handlePermissionChangeForRead('wages')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.write"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.create"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.delete"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.import"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.wages.export"
                            :disabled="!permissions.wages.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Schedule</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.schedule.read" @change="handlePermissionChangeForRead('schedule')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.write"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.create"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.delete"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.import"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.schedule.export"
                            :disabled="!permissions.schedule.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Shift</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.shift.read" @change="handlePermissionChangeForRead('shift')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.write"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.create"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.delete"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.import"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.shift.export"
                            :disabled="!permissions.shift.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Leaves</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.leave.read" @change="handlePermissionChangeForRead('leave')"  />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.write"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.create"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.delete"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.import"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.leave.export"
                            :disabled="!permissions.leave.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Clients</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.client.read" @change="handlePermissionChangeForRead('client')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.write"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.create"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.delete"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.import"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.client.export"
                            :disabled="!permissions.client.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Projects</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.project.read" @change="handlePermissionChangeForRead('project')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.write"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.create"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.delete"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.import"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.project.export"
                            :disabled="!permissions.project.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tasks</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.task.read"  @change="handlePermissionChangeForRead('task')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.write"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.create"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.delete"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.import"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.task.export"
                            :disabled="!permissions.task.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Chats</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.show_navbar" />
                          <span class="checkmark"></span>
                        </label> 
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.chat.read" @change="handlePermissionChangeForRead('chat')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.write"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.create"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.delete"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.import"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.chat.export"
                            :disabled="!permissions.chat.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Assets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.asset.read" @change="handlePermissionChangeForRead('asset')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.write"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.create"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.delete"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.import"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.asset.export"
                            :disabled="!permissions.asset.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Timing Sheets</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.timing_sheet.read" @change="handlePermissionChangeForRead('timing_sheet')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.write"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.create"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.delete"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.import"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.timing_sheet.export"
                            :disabled="!permissions.timing_sheet.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Overtime</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.overtime.read" @change="handlePermissionChangeForRead('overtime')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.write"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.create"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.delete"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.import"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.overtime.export"
                            :disabled="!permissions.overtime.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Office</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.read" @change="handlePermissionChangeForRead('office')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.write"
                            :disabled="!permissions.office.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.create"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.delete"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.import"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.office.export"
                             :disabled="!permissions.office.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Organization Documents</td>
                      <!-- <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.office.show_navbar" />
                          <span class="checkmark"></span>
                        </label>
                      </td> -->
                      <td class="text-center">
                        <label class="custom_check">
                          <input type="checkbox" v-model="permissions.organization_document.read" @change="handlePermissionChangeForRead('organization_document')" />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.write"
                            :disabled="!permissions.organization_document.read"

                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.create"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.delete"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.import"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td class="text-center">
                        <label class="custom_check">
                          <input
                            type="checkbox"
                            name="rememberme"
                            class="rememberme"
                            v-model="permissions.organization_document.export"
                             :disabled="!permissions.organization_document.read"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="submit-section">
                <button class="btn btn-primary submit-btn" :disabled="disableButton" @click.prevent="editEmployee">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Edit Employee Modal -->

    <!-- Delete Employee Modal -->
    <div class="modal custom-modal fade" id="delete_employee" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="form-header">
              <h3>Delete Employee</h3>
              <p>Are you sure want to delete?</p>
            </div>
            <div class="modal-btn delete-action">
              <div class="row">
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-primary continue-btn"
                    @click="deleteEmployee"
                    >Delete</a
                  >
                </div>
                <div class="col-6">
                  <a
                    href="javascript:void(0);"
                    data-bs-dismiss="modal"
                    class="btn btn-primary cancel-btn"
                    ref="delete_employee_close"
                    >Cancel</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Delete Employee Modal -->

      <!-- Import Employee Modal -->
      <div id="import_employee" class="modal custom-modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
          <h5 class="modal-title">Import Employees</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="impempclose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          </div>
          <div class="modal-body">
          <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
            <div class="input-block mb-3">
            <label class="col-form-label"
              >File <span class="text-danger">*</span>
            </label>
            <Field
                name="employee_import_file"
                type="file"
                class="form-control"
                :class="{ 'is-invalid': errors && errors?.employee_import_file }"
                @change="handleFileUpload($event)"
                accept=".xlsx,.xls"	
                ref="importFile"
              />
              <div class="invalid-feedback">{{ errors && errors?.employee_import_file }}</div>
              <div class="filesshow text-danger" id="file"></div>
              <p> <a :href="user_demo_file" target="_blank">Click here </a> for download Demo file </p>
            </div>
            <div class="submit-section">
            <a class="btn btn-primary submit-btn" data-bs-toggle="modal"
            data-bs-target="#confirm_import_emp" >Submit</a>
            </div>
          </Form>
          </div>
        </div>
        </div>
      </div>
      <!-- /Import Employee Modal -->
       <!-- Delete Performance Appraisal Modal -->
      <div class="modal custom-modal fade" id="confirm_import_emp" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div class="form-header">
                <h3>Import Employees</h3>
                <p>Are you sure want to import Employees ?</p>
              </div>
              <div class="modal-btn delete-action">
                <div class="row">
                  <div class="col-6">
                    <a href="javascript:void(0);" class="btn btn-primary continue-btn" @click="onSubmit"
                      >Yes</a
                    >
                  </div>
                  <div class="col-6">
                    <a
                      href="javascript:void(0);"
                      data-bs-dismiss="modal"
                      ref="closeConfirmModal"
                      @click="closeModals"
                      class="btn btn-primary cancel-btn"
                      >Cancel</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Delete Performance Appraisal Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notification } from "ant-design-vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from 'axios';
export default {
  emits:['reloadEmp'],
  data() {
    return {
      genders:[{
        key:'',
        value:'Select'
      },{
        key:'f',
        value:'Female'
      },{
        key:'m',
        value:'Male'
      },
      {
        key:'o',
        value:'Others'
      }],
      errors:null,
      editfile:null,
      employee_errors: {},
      confirm_password_error:null,
      disableButton:false,
      select2: null,
      startdate:new Date(),
      dateFormat: 'dd-MM-yyyy',
      Company: ["Global Technologies", "Delta Infotech"],
      employee:{
        first_name:null,
        last_name:null,
        username:null,
        email:null,
        password:null,
        confirm_password:null,
        employee_id:null,
        gender:null,
        joining_date_dd:null,
        birth_date_dd:null,
        contact_number:null,
        company_id:null,
        office_id:null,
        department_id:null,
        designation_id:null,
        joining_date:null,
        birth_date:null,
        permission:null,
        office_id:null
      },
      permissions:{
  holiday: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  employee: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  department: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  designation: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  wages: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  schedule: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  shift: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  leave: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  client: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  project: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  task: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  chat: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  asset: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  timing_sheet: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  overtime: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  leave_type: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  attendance: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  incident: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  office: {
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  },
  organization_document:{
    read: false,
    write: false,
    delete: false,
    create: false,
    export: false,
    import: false
  }

},
      offices:[]
      
    };
  },
  props: {
    employeeData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    clearForm: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    clearEMP: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  watch:{
    clearEMP(newVal)
    {
        if(newVal)
        {
          this.defaultPermission();
          this.employee = {
              first_name:null,
              last_name:null,
              username:null,
              email:null,
              password:null,
              confirm_password:null,
              employee_id:null,
              gender:null,
              joining_date_dd:null,
              birth_date_dd:null,
              contact_number:null,
              company_id:null,
              office_id:null,
              department_id:null,
              designation_id:null,
              joining_date:null,
              birth_date:null,
              permission:null,
              office_id:null
            }
        }
    },
    clearForm(newVal)
    {
        if(newVal)
        {
          const fileInput = document.querySelector('input[name="employee_import_file"]');
          if (fileInput) {
            fileInput.value = '';
          }
          this.$refs.importFile.value = ''
          this.editfile = null
        }
    },
    employeeData(newVal)
    {

      if(newVal && newVal != 1)
      {
        this.employee = newVal;
        this.employee.joining_date_dd = newVal?.joining_date ? new Date(newVal?.joining_date) : null;
        this.employee.birth_date_dd = newVal?.birth_date != null ? new Date(newVal?.birth_date) : null;
        this.employee.password = null;
        this.employee.confirm_password = null;

        if(newVal && newVal?.user_permission && newVal?.user_permission?.permissions && newVal?.user_permission?.permissions != null)
        {
          const permission_data = JSON.parse(newVal?.user_permission?.permissions);
          if(permission_data && permission_data != null){
            this.permissions = permission_data;
          }
        }
      }
      
    }
  },
  components: {
    Form,
    Field,
  },
  methods:{
    ...mapMutations(['SET_EMPLOYEE_SEARCH_FIELD']),
    ...mapActions(
      ['fetchCompanies','fetchDesignations','fetchDepartments','createEmployee',
      'getDateFormate','fetchEmployees','updateEmployee','deleteEmployeeData','fetchOffice']),
    closeModals()
    {
      const fileInput = document.querySelector('input[name="employee_import_file"]');
      if (fileInput) {
        fileInput.value = '';
      }
      this.$refs.importFile.value = ''
      this.editfile = null
      this.$refs.impempclose.click();
    },
    designation_change(selected_designation_id = null )
    {
      var record = this.Designations.find(function(item) {
          return item.id === selected_designation_id;
      });

     

     if(record && record?.permissions && record?.permissions != 'null' && record?.permissions != null)
     {
          const permission_data = JSON.parse(record?.permissions);
          if(permission_data && permission_data != null){

        
            this.$nextTick(()=>{
              this.permissions = permission_data;
            
            })
          }
          else
          {
            this.defaultPermission();
          }
     }
     else{
     this.defaultPermission();

     }

      
    },
    onSubmit()
    {

      this?.$refs?.closeConfirmModal.click();

        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var form = new FormData();

        form.append('employee_import_file', this.editfile);

        const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            };

        axios.post("/employees/import", form,config)
              .then( (response) => {


          this.editfile = null
          this.$refs.importFile.value = ''
            
            loader.hide();
            
            notification.open({
              message: response?.data?.message || 'Successfully Imported',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

          this.$refs.impempclose.click();
            
            this.fetchEmployees();

            this.defaultPermission();

            this.SET_EMPLOYEE_SEARCH_FIELD(this.getEMployeeSeatchData);
              
        }).catch(error => {

          this.editfile = null
          this.$refs.importFile.value = ''

          const button = document.querySelector('.import-emp-button');

          if(button)
          {
            button.click();
          }
              
          loader.hide();
          
          if(error.response){
          
            var response = (error.response);
              
            notification.open({
              message: response?.data?.message || 'Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
          
            });
    },
    handleFileUpload(event){
      this.editfile = event.target.files[0];
    },
    async addEmployee(){
        this.disableButton = true;
        this.employee_errors = null;
        this.confirm_password_error = null;
        this.employee.joining_date = this.employee?.joining_date_dd ? await this.getDateFormate(this.employee?.joining_date_dd) : null;
        this.employee.birth_date = this.employee?.birth_date_dd != null ? await this.getDateFormate(this.employee?.birth_date_dd) : null;


        this.employee.permission = this.permissions;

        if(this.employee.password != null && this.employee.password != this.employee.confirm_password)
        {
          this.confirm_password_error = 'Password and Confirm Password not matching'
        }

        const data = await this.createEmployee(this.employee);

        if(data?.error && data.error != null || this.confirm_password_error != null)
        {
          this.employee_errors = data.error;
          this.disableButton = false;

          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
              zIndex: 1100,
            },
          });
        }
        else
        {
          notification.open({
            message: 'Successfully Employee Created',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
          this.disableButton = false;

          this.$refs.add_employee_close.click();

          this.employee_errors = null;

          this.$emit('reloadEmp');

          this.fetchEmployees();

          this.defaultPermission();

          this.SET_EMPLOYEE_SEARCH_FIELD(this.getEMployeeSeatchData);
        }
    },
    async editEmployee()
    {
        this.confirm_password_error != null
        this.disableButton = true;
        this.employee_errors = null;
        this.employee.joining_date = this.employee?.joining_date_dd ? await this.getDateFormate(this.employee?.joining_date_dd) : null;
        this.employee.birth_date = this.employee?.birth_date_dd ?await this.getDateFormate(this.employee?.birth_date_dd) : null;


        this.employee.permission = this.permissions;

        if(this.employee.password != null && this.employee.password != this.employee.confirm_password)
        {
          this.confirm_password_error = 'Password and Confirm Password not matching'
        }

        if(this.employee.password == null || this.employee.password == '')
        {
          delete this.employee.password;
          delete this.employee.confirm_password;
        }

        const data = await this.updateEmployee(this.employee);

        if(data?.error && data.error != null)
        {
          this.employee_errors = data.error;
          this.disableButton = false;
          notification.open({
            message: 'Please fill up the mandatory fields.',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        else
        {
          notification.open({
            message: 'Successfully Employee Created',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
          this.disableButton = false;

          this.$refs.update_employee_close.click();

          this.defaultPermission();

          this.fetchEmployees();

          this.$emit('reloadEmp');

          this.SET_EMPLOYEE_SEARCH_FIELD(this.getEMployeeSeatchData);
        }
    },
    async deleteEmployee()
    {
      const delete_employee_id = this.employee.id

      const data = await this.deleteEmployeeData(delete_employee_id);

      if(data.status == 200)
      {
        this.$emit('reloadEmp');
        this.$refs.delete_employee_close.click();
        this.fetchEmployees();
        notification.open({
            message: 'Successfully Employee Deleted',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
            },
          });
      }
      else
      {
        this.$refs.delete_employee_close.click();
        notification.open({
            message: 'Something went wrong, Please try again later',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
      }
    },
    defaultPermission(){
      this.permissions = {
          holiday: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          employee: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          department: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          designation: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          wages: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          schedule: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          shift: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          leave: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          client: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          project: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          task: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          chat: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          asset: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          timing_sheet: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          overtime: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          leave_type: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          attendance: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          incident: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          office: {
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          },
          organization_document:{
            read: false,
            write: false,
            delete: false,
            create: false,
            export: false,
            import: false
          }
        }
    },
    handlePermissionChangeForRead(module = null)
    {
      const readPermission = this?.permissions?.[module]?.read;

      if(!readPermission)
      {
        this.permissions[module].create = false;
        this.permissions[module].write = false;
        this.permissions[module].delete = false;
        this.permissions[module].import = false;
        this.permissions[module].export = false;
      }          
    },
   async fetchOffices(){
      await axios.get('/office/all').then((response)=>{
  
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        this.offices = response?.data?.data || [];

      }).catch(error =>{
        console.log('error in fetching employee',error);
      })
    },
  },
  computed: {
    ...mapGetters(['getCompanies','getDesignations','getDepartments','getEMployeeSeatchData','getOffice']), 
    Companies() {
      return this.getCompanies?.record ? this.getCompanies.record : []; 
    },
    Designations() {
      return this.getDesignations ? this.getDesignations : [];
    },
    Departments() {
      return this.getDepartments ? this.getDepartments : [];
    },
    Office(){
        return this.getOffice?.data ? this.getOffice.data : [];
    },
    user_demo_file()
    {
      return `${process.env.VUE_APP_IMAGE_BASE_URL}sample/user.xlsx` 
    }
  },
  async created() {
    let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
    await this.fetchCompanies(); 
    await this.fetchDepartments();
    await this.fetchDesignations();
    await this.fetchOffices();
    loader.hide()
  },
  setup() {
    const schema = Yup.object().shape({
		employee_import_file: Yup.string().required("File is required"),
    });
	return {
      schema
	}
  }
  
};
</script>
