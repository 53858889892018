<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <layout-sidebar></layout-sidebar>

    <!-- Page Wrapper -->
    <div class="page-wrapper" ref="formContainer">
      <div class="content container-fluid">
        <!-- Page Header -->
        <breadcrumb :title="title" :path="path" :text="text" />
        <!-- /Page Header -->

        <div class="row">
          <div class="col-md-4">
            <div class="card punch-status">
              <div class="card-body">
                <h5 class="card-title">
                  Timesheet <small class="text-muted">{{ activeDate }}</small>
                </h5>
                <div class="punch-det">
                  <h6>Punch In at</h6>
                  <p v-if="punchInTime">{{ activeDay }}, {{ activeDate }} {{ punchInTime }}</p>
                  <p v-else> Not punched in yet for today. </p>
                </div>
                <div class="punch-info">
                  <div class="punch-hours">
                    <span>{{ liveTimer ? liveTimer : 0 }} hrs</span>
                  </div>
                </div>
                <div class="punch-btn-section">
                  <!-- <div class="pt-2 pb-3">
                    <label>Select Office </label>
                    <select class="form-control select-form select-form-lg" v-model="office_id" >
                      <option v-for="(offic,index) in offices" :key="'office_index'+index" :value="offic.office_id">{{ offic.office_name }}</option>
                    </select>
                  </div> -->
                  <button v-if="activePunchType == null || activePunchType == 1" type="button" class="btn btn-primary punch-btn" @click="punchIn()">
                    Punch In
                  </button>
                  <div v-else>
                    <!-- <div class="pt-2 pb-3">
                      <label>Select Office </label>
                      <select class="form-control select-form select-form-lg" v-model="office_id" >
                        <option v-for="(offic,index) in offices" :key="'office_index'+index" :value="offic.office_id">{{ offic.office_name }}</option>
                      </select>
                    </div> -->
                    <div class="pt-2 pb-3"> 
                      <vue-select
                        :options="punchOutOptions"
                        id="AddassetsDepartment"
                        placeholder="Select reason for Punch Out"
                        v-model="punchOutReason"
                      />

                    </div>
                    <button  type="button" class="btn btn-primary punch-btn" @click="punchOut()">
                      Punch Out
                    </button>
                  </div>
                </div>
                <div class="statistics">
                  <div class="row">
                    <div class="col-md-6 col-6 text-center">
                      <div class="stats-box">
                        <p>Break</p>
                        <h6>{{ today?.breakHours && today?.breakHours != null && today?.breakHours != '' ? today.breakHours + ' hrs' : '00:00' }}</h6>
                      </div>
                    </div>
                    <div class="col-md-6 col-6 text-center">
                      <div class="stats-box">
                        <p>Overtime</p>
                        <h6>{{ today?.otHours && today?.otHours != null && today?.otHours != '' ? today.otHours + ' hrs' : '00:00' }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card att-statistics">
              <div class="card-body">
                <h5 class="card-title">Statistics</h5>
                <div class="stats-list">
                  <div class="stats-info">
                    <p>
                      Today <strong> {{ liveTimer ? liveTimer : '00:00' }} hrs<small>/ 8 hrs</small></strong>
                    </p>
                    <div class="progress">
                    
                      <div class="progress-bar bg-primary" :style="{ width: liveTimerProgress + '%' }" 
                        role="progressbar" 
                        :aria-valuenow="liveTimerProgress" 
                        aria-valuemin="0" 
                        aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                  <div class="stats-info">
                    <p>
                      This Week <strong>{{ weekly?.workingHours ? weekly?.workingHours : '00:00' }} hrs <small>/ 40 hrs</small></strong>
                    </p>
                    <div class="progress">
                      <div
                        class="progress-bar bg-warning w-31" :style="{ width: calculateTimePercentage(weekly?.workingHours,40) + '%' }" 
                        role="progressbar"
                        :aria-valuenow="calculateTimePercentage(weekly?.workingHours,40)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="stats-info">
                    <p>
                      This Month <strong>{{ monthly?.workingHours ? monthly?.workingHours : '00:00' }} hrs <small>/ 160 hrs</small></strong>
                    </p>
                    <div class="progress">
                      <div
                        class="progress-bar bg-success w-62" :style="{ width: calculateTimePercentage(monthly?.workingHours,160) + '%' }"
                        role="progressbar"
                        :aria-valuenow="calculateTimePercentage(monthly?.workingHours,160)"
                        aria-valuemin="0"
                        aria-valuemax="160"
                      ></div>
                    </div>
                  </div>
                  <div class="stats-info">
                    <p>
                      Weekly Overtime <strong>{{ weekly?.otHours ? weekly?.otHours : '00:00' }} hrs <small></small></strong>
                    </p>
                    <div class="progress">
                      <div
                        class="progress-bar bg-warning w-31" :style="{ width: calculateTimePercentage(weekly?.otHours,40,true) + '%' }" 
                        role="progressbar"
                        :aria-valuenow="calculateTimePercentage(weekly?.otHours,40)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div class="stats-info">
                    <p>Monthly Overtime <strong>{{ monthly?.otHours ? monthly?.otHours : '00:00' }} hrs</strong></p>
                    <div class="progress">
                      <div
                        class="progress-bar bg-warning w-31" :style="{ width: calculateTimePercentage(monthly?.otHours,160,true) + '%' }" 
                        role="progressbar"
                        :aria-valuenow="calculateTimePercentage(monthly?.otHours,160)"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card recent-activity">
              <div class="card-body">
                <h5 class="card-title">Today Activity</h5>
                <ul class="res-activity-list">
                  <li v-for="punchData in today_active_data" :key="'punch_activity_'+punchData.employee_attendances_details_id">
                    <p class="mb-0">{{ punchData?.employee_attendances_details_type == 0 ? 'Punch In' : 'Punch Out' }} at</p>
                    <p class="res-activity-time">
                      <i class="fa-regular fa-clock"></i>
                      {{ punchData?.employee_attendances_details_time && punchData?.employee_attendances_details_time != null ? convertToLocalTime(punchData?.employee_attendances_details_time) : '' }}
                      {{ punchData?.punch_out_reason && punchData?.punch_out_reason != null ? ' [ '+punchData?.punch_out_reason+' ] ' : ''  }}  
                    </p>
                  </li>
                 </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Search Filter -->
        <attendance-employee-filter @filter-attendance="onFilterAttendance"></attendance-employee-filter>
        <!-- /Search Filter -->
        <div class="row">
          <div class="col-md-12">
            <div class="showentries mb-3">
              <label
                >Show
                <select v-model="pagination.pageSize" @change="updatePerPage">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                entries</label
              >
            </div>
            <div>
              <a-table
                class="stripped table-hover"
                :columns="columns"
                :data-source="data"
				        :pagination="pagination"
              >
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'date'">
                    <div>{{ record?.employee_attendance_date }}</div>
                  </template>
                  <template v-if="column.key === 'punchin'">
                    <div>{{ record?.employee_attendance_punchin_time && record?.employee_attendance_punchin_time != null ? convertUTCToLocal(record?.employee_attendance_punchin_time) : ''}}</div>
                  </template>
                  <template v-if="column.key === 'punchout'">
                    <div>{{ record?.final_punch_out_time && record?.final_punch_out_time != null ? convertUTCToLocal(record?.final_punch_out_time) : '-'}}</div>
                  </template>
                  <template v-if="column.key === 'production'">
                    <div>{{ record?.new_key?.workingHours ? record?.new_key?.workingHours : 0 }} hrs</div>
                  </template>
                  <template v-if="column.key === 'break'">
                    <div>{{ record?.new_key?.breakHours ? record?.new_key?.breakHours : 0 }} hrs</div>
                  </template>
                  <template v-if="column.key === 'overtime'">
                    <div>{{ record?.new_key?.otHours ? record?.new_key?.otHours : 0 }} hrs</div>
                  </template>
                </template>
              </a-table>
            </div>
          </div>
        </div>
      </div>
      <!-- /Page Content -->
    </div>
    <!-- Page Wrapper -->
  </div>
</template> 

<script>
import AttendanceEmployee from "@/assets/json/attendance-employee.json";
import { mapActions, mapGetters } from 'vuex';
import { notification } from "ant-design-vue";
import { DateTime } from 'luxon';
import axios from 'axios';
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10,};

      const columns = [
  {
	  title: "#",
	  dataIndex: "id",
	  key: "id",
	  customRender: ({ index }) => {
		return pagination.current === 1 ? index + 1 : (pagination.current - 1) * pagination.pageSize + (index + 1);
	  },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date"
  },
  {
    title: "Punch In",
    dataIndex: "punchin",
    key: "punchin"
  },
  {
    title: "Punch Out",
    dataIndex: "punchout",
    key: "punchout"
  },
  {
    title: "Production",
    dataIndex: "production",
    key: "production"
  },
  {
    title: "Break",
    dataIndex: "break",
    key: "break"
  },
  {
    title: "Overtime",
    dataIndex: "overtime",
    key: "overtime"
  },
];

export default {
  data() {
    return {
      AttendanceEmployee: AttendanceEmployee,
      title: "Attendance",
      path: "Dashboard",
      text: "Attendance",
      date: new Date(),
      today_active_data: null,
      indexData:null,
      perpage: 10,
      pagination: pagination,
      data:[],
      columns,
      search:{},
      weekly:null,
      monthly:null,
      today:{
        breakHours:'00:00',
        workingHours:'00:00',
        otHours:'00:00'
      },
      punchOutReason:null,
      punchOutOptions:[
        "On Break",
        "On Lunch",
        "On Hourly Leave",
        "Final Punch Out"
      ],
      liveTimer:null,
      liveBeakTime:'00:00',
      liveTimerProgress:0,
      offices:[],
      office_id:null
    };
  },
  computed: {
    activeDate() {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return this.date.toLocaleDateString('en-US', options);
    },
    activeDay() {
      const options = { weekday: 'short' };
      return this.date.toLocaleDateString('en-US', options);
    },
    punchInTime() {
      if (Array.isArray(this.today_active_data)) {
        const punchInRecord = this.today_active_data.find(record => record.punch_type === 'punch-in');
        
        if (punchInRecord) {
          return punchInRecord?.employee_attendances_details_time && punchInRecord?.employee_attendances_details_time != null ?  this.convertToLocalTime(punchInRecord.employee_attendances_details_time) : ''
        }
      }
      return null;
    },
    activePunchType(){
      if (Array.isArray(this.today_active_data)) {
        
        const lngth = this.today_active_data.length;

        if(lngth)
        {
          this.setOfficeID(this.today_active_data[(lngth-1)]['office_id'])
          return this.today_active_data[(lngth-1)]['employee_attendances_details_type'];
        }
        else
        {
          return null;
        }
        
      }
      else
      {
        return null; 
      }
    },
    todayWorkingProgress(){
      return this.calculateTimePercentage(this.liveTimer,8);
    }
  },
  methods:{
    ...mapActions(['fetchEmployeeAttendance','punchInfortoday','punchOutfortoday','attendanceIndex']),
setOfficeID(office_id = null)
{

  let user = localStorage.getItem('user');
  
  if(user && user != null)
  {
    user = JSON.parse(user);
  }

  this.office_id = office_id ? office_id : user?.office_id ? user?.office_id : null;
},
convertToLocalTime(utcTime) {
      const utcDateTime = DateTime.fromFormat(utcTime, 'yyyy-MM-dd HH:mm:ss');
      const istDateTime = utcDateTime.setZone(process.env.VUE_APP_FRONT_END_TIME_ZONE);
      const istTimeString = istDateTime.toLocaleString(DateTime.DATETIME_SHORT);

      return istTimeString;
    },

    convertUTCToLocal(time) {
      const [hours, minutes, seconds] = time.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';

        let m = minutes;
        let hours12 = hours % 12;
        hours12 = hours12 === 0 ? 12 : hours12; 
        if(m < 10)
        {
          m = '0'+m;
        }

        if(hours12 < 10)
        {
          hours12 = '0'+hours12;
        }
        const formattedTime = `${hours12}:${m}`;
        return `${formattedTime} ${period}`;
      },
    async punchIn(){

      let user = localStorage.getItem('user');

      var data;
  
      if(user && user != null)
      {
        user = JSON.parse(user);
      }

      if(user?.work_from === 1)
      {
        var lat  = null;
        var lng = null;

        await axios.get('user/location').then(response => {

          lat = response?.data?.data?.result?.geoplugin_latitude || null;
          lng = response?.data?.data?.result?.geoplugin_longitude || null;

          data =  this.punchInfortoday({'user_lat':lat,'user_lng':lng});

        }).catch(error => {
            notification.open({
              message: error?.data?.message || 'User Location not found !',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
        })
      }
      else{
        data = this.punchInfortoday({});
      }

      data.then(response => {
        if(response.status == 200)
        {
          notification.open({
              message: 'Punch In Successfully',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            this.fetchData();
        }
        if(response?.error)
        {
          notification.open({
            message: response?.data?.message || 'Something went wrong!',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        else if(response?.message && response?.message != '')
        {
          notification.open({
              message: response?.message || 'Something went wrong!',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
          });
        }
      }).catch(error => {
        console.log('error',error)
      })
    },
    async punchOut()
    {
      
      if(this.punchOutReason == null || this.punchOutReason == '')
      {
        notification.open({
            message: 'Please select punch out reason',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });

        return false;
      }

      let user = localStorage.getItem('user');

      var data;
  
      if(user && user != null)
      {
        user = JSON.parse(user);
      }

      if(user?.work_from === 1)
      {
        var lat  = null;
        var lng = null;

        await axios.get('user/location').then(response => {

          lat = response?.data?.data?.result?.geoplugin_latitude || null;
          lng = response?.data?.data?.result?.geoplugin_longitude || null;

          data =  this.punchOutfortoday({'office_id' : this.office_id,'user_lat':lat,'user_lng':lng,'punch_out_reason':this.punchOutReason});

        }).catch(error => {
            notification.open({
              message: error?.data?.message || 'User Location not found !',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });
        })
      }
      else{
        data = this.punchOutfortoday({'punch_out_reason':this.punchOutReason});
      }

      data.then(response => {
        if(response.status == 200)
        {
          notification.open({
              message: 'Punch In Successfully',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_SUCCESS_COLOR,
              },
            });

            this.fetchData();
        }
        if(response?.error)
        {
          notification.open({
            message: response?.data?.message || 'Something went wrong!',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
        else if(response?.message && response?.message != '')
        {
          notification.open({
              message: response?.message || 'Something went wrong!',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
          });
        }
      }).catch(error => {
        console.log('error',error)
      })

      
    },
    async fetchData(){
      let loader = this.$loading.show({
				// Optional parameters
				container: this.$refs.formContainer,
				canCancel: false
			});
      await this.fetchEmployeeAttendance().then(response =>{
        if(response)
        {
          this.today_active_data = [...response].sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }
      });
      var params = {
        params: { per_page: this.pagination.pageSize, search: this.search }
      };
      const datas  = await this.attendanceIndex(params);

      const data = datas?.data || null;

      this.weekly = data?.weekly && data?.weekly != null ? data.weekly : null
      this.monthly = data?.monthly && data?.monthly != null ? data.monthly : null
      if( data?.today && data?.today != null)
      {
        this.today = data.today 
      }

      
      if(data?.attendance?.data)
      {
        this.data = data?.attendance?.data?.sort((a, b) => {
            const dateA = new Date(a.employee_attendance_date);
            const dateB = new Date(b.employee_attendance_date);
            return dateB - dateA;
        }) || [];
      }
      else
      {
        this.data = []
      }

      loader.hide()
    },
    updatePerPage(){
      var params = {
            params: { per_page: this.pagination.pageSize }
          };
          this.fetchData(params);
      
    },
    async onFilterAttendance(param = null){
        this.search = param;
        await this.fetchData()
    },
    calculateTimePercentage(time,TotalHours,isOt = false) {

      if(time)
      {
      
        const [hours, minutes] = time.split(':').map(Number);
        const totalMinutes = hours * 60 + minutes;
        if (totalMinutes >= TotalHours * 60 && !isOt) {
          return 100;
        } else {
          return ((totalMinutes / (TotalHours * 60)) * 100).toFixed(2);
        }
      }
      else
      {
        return 0;
      }
      
    },
    async fetchOffies(){

      let user = localStorage.getItem('user');
      if(user && user != null)
      {
        user = JSON.parse(user);
      }

      await axios.get('/user/office/'+user?.id).then((response)=>{
  
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        this.offices = response?.data?.data || [];

      }).catch(error =>{
        console.log('error in fetching employee',error);
      })
    },
  },
  async created(){

    this.setOfficeID();
   await this.fetchData();
   await this.fetchOffies();

   let currentTime = this.today?.workingHours;
   this.liveTimer = this.today?.workingHours;
   this.liveTimerProgress = this.calculateTimePercentage(currentTime,8);
   setInterval(() => {

    if(this.activePunchType !== null && this.activePunchType !== 1)
    {
      let timeArray = currentTime.split(':');
      let totalMinutes = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
      totalMinutes += 1;
      let newHours = Math.floor(totalMinutes / 60);
      let newMinutes = totalMinutes % 60;
      newHours = newHours < 10 ? '0' + newHours : newHours;
      newMinutes = newMinutes < 10 ? '0' + newMinutes : newMinutes;

      currentTime = `${newHours}:${newMinutes}`;

      this.liveTimer = currentTime;
        this.liveTimerProgress = this.calculateTimePercentage(currentTime,8);
    }
   },60000);

  }
};
</script>
