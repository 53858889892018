import store from './store';

export function showNotification(){
    Pusher.logToConsole = true;
    const pusher = new Pusher('919507ed878b476c1df2', {
        cluster: 'ap2'
    });

    const global_channel = pusher.subscribe('global-channel');

    let user = localStorage.getItem('user');

    if(user && user != null)
    {
        user = JSON.parse(user);
        global_channel.bind('notification-'+user?.id, function(data) {

            store.commit('SET_GLOBAL_NOTIFICATION_DATA', data?.message);
            store.commit('SET_GLOBAL_NOTIFICATION_BADGE', (store?.state?.globalNotificationsBadge + 1));
        });
    }
}