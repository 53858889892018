<template>
  <div ref="formContainer">
    <div class="row">
      <div class="col-md-12">
        <div class="showentries mb-3">
          <label
            >Show
            <select v-model="pagination.pageSize" @change="updatePagination">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div class="table-responsive">
          <a-table class="stripped table-hover" :columns="columns" :data-source="asset_data" :pagination="pagination"
                  @change="handleTableChange">
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'asset_user_name'">
                <h2 class="table-avatar" v-if="record?.user?.id != null">
                      <router-link :to="'/profile/'+record?.user?.id" class="avatar"
                        >
                        <img v-if="record?.user?.avatar"
                        :src="getEmployeeAvatar(record.user.avatar)"
                        alt="User Image"
                        @error="handleImageError(record)"
                        />
                        <img v-else
                        :src="require(`@/assets/img/profiles/avatar-02.jpg`)"
                            alt="User Image" 
                        />
                    </router-link>
                      <router-link :to="'profile/'+record?.user?.id"
                        >{{ record?.user?.first_name }} {{ record?.user?.last_name }} </router-link
                      >
                </h2>
                <p class="text-start" v-else> Not Assigned </p>

              </template>
              <template v-if="column.key === 'name'">

                <router-link :to="'/assets-details/'+record?.asset_id" >
                  <p>
                    <img class="asset-image-sm" v-if="record && record.files && record.files.length > 0 && get_image_path(record.files) != null" :src="get_image_path(record.files)" /> 
                    <img v-else src="@/assets/img/keyboard.png" alt="Keyboard Image" />  {{ record?.name }}
                  </p> 
                </router-link>
              </template>
              <template v-if="column.key === 'warranty_end'">
                  {{ get_warranty_date(record.purchase_date, record.warranty) }}
              </template>
              <template v-if="column.key === 'warranty'">
                  <p class="text-center">{{ record?.warranty }}</p>
              </template>
              <!-- <template v-if="column.key === 'Status'">
                <div class="text-center">
                  <div class="dropdown action-label">
                    <a
                      class="btn btn-white btn-sm btn-rounded dropdown-toggle"
                      href="javascript:;"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i :class="addassetstatus[record.status ? record.status : 0]['class']"></i>
                      {{ addassetstatus[record.status ? record.status : 0]['name'] }}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="fa-regular fa-circle-dot text-danger"></i> Pending</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="fa-regular fa-circle-dot text-success"></i> Approved</a
                      >
                      <a class="dropdown-item" href="javascript:;"
                        ><i class="fa-regular fa-circle-dot text-info"></i> Returned</a
                      >
                    </div>
                  </div>
                </div>
              </template> -->
              <template v-if="column.key === 'action'">
                <div class="text-end" v-if="hasReadPermission || hasEditPermission || hasDeletePermission">
                  <div class="dropdown dropdown-action">
                    <a
                      href="javascript:;"
                      class="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      ><i class="material-icons">more_vert</i></a
                    >
                    <div class="dropdown-menu dropdown-menu-right">
                      <router-link v-if="hasReadPermission"  class="dropdown-item" :to="'/assets-details/'+record?.asset_id" > <i class="fa fa-eye m-r-5"></i> View </router-link>
                      <a v-if="hasEditPermission"
                        class="dropdown-item"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#add_asset"
                        @click="$emit('asset-edit-data',record)"
                        ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
                      >
                      <!-- <a
                        class="dropdown-item"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#asset_upload"
                        @click="upload_images(record?.asset_id)"
                        ><i class="fa fa-upload m-r-5"></i> Upload Doc(s)</a
                      > -->
                      <a v-if="hasEditPermission"
                        class="dropdown-item"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#assign_asset"
                        @click="assignAsset(record?.asset_id)"

                        ><i class="fa fa-share m-r-5"></i> Assign To </a
                      >
                      <a v-if="hasDeletePermission"
                        class="dropdown-item"
                        href="javascript:;"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_asset"
                        @click="deleteID = record.asset_id"
                        ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
                      >
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
  <div id="asset_upload" class="modal custom-modal fade" role="document">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload Asset Images</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="upload_asset_close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <input
                  type="file"
                  class="form-control"
                  data-buttontext="Choose File"
                  data-icon="false"
                  data-classbutton="btn btn-default"
                  data-classinput="form-control inline input-s"
                  name="documents[]"
                  multiple
                  accept=".jpg, .jpeg, .png, .doc, .docx, .pdf"
                  ref="attachments" 
              />
            </div>
            <div class="col-md-12">
              <div class="submit-section">
                  <button class="btn btn-primary submit-btn" :disabled="disableButton" @click="onAttachIncident">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="assign_asset" class="modal custom-modal fade" role="document">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign Asset</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            ref="assign_asset_close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="input-block mb-3">
                <label class="col-form-label">Asset User</label>
                <select
                  v-model="assign_emp"
                  @change="handleChangeEvent('asset_user_id')"
                  :class="{ 'is-invalid': clientError.includes('asset_user_id') }"
                  class="form-select form-select-lg"
                >
                  <option v-for="emp in employees" :key="'emp'+emp.id" :value="emp.id">
                    {{ emp.first_name }} {{ emp.last_name }}
                  </option>
                </select>
                <span class="text-danger" v-if="errors && errors?.asset_user_id && errors?.asset_user_id != null">{{ errors?.asset_user_id[0]  }}</span>
              </div>
            </div>
            <div class="col-md-12">
              <div class="submit-section">
                  <button class="btn btn-primary submit-btn" :disabled="disableButton" @click="onAssignAsset">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { notification } from "ant-design-vue";
var pagination = {total: 0,
	    current: 1,
	    pageSize: 10};

const columns = [
  {
    title: "Current Asset User",
    dataIndex: "asset_user_name",
    key:"asset_user_name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Asset Name",
    dataIndex: "AssetName",
    dataIndex: "name",
    key:"name",
    sorter: {
      compare: (a, b) => {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Asset ID",
    dataIndex: "uuid",
    key:"uuid",
    sorter: {
      compare: (a, b) => {
        a = a.uuid.toLowerCase();
        b = b.uuid.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Purchase Date",
    dataIndex: "purchase_date",
    key:"purchase_date",
    sorter: {
      compare: (a, b) => {
        a = a.purchase_date;
        b = b.purchase_date;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Warrenty (In Month)",
    dataIndex: "warranty",
    key:"warranty",
    class:'text-center',
    sorter: {
      compare: (a, b) => {
        a = a.warranty;
        b = b.warranty;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Warrenty End",
    dataIndex: "warranty_end",
    key:"warranty_end"
    // sorter: {
    //   compare: (a, b) => {
    //     a = a.WarrentyEnd.toLowerCase();
    //     b = b.WarrentyEnd.toLowerCase();
    //     return a > b ? -1 : b > a ? 1 : 0;
    //   },
    // },
  },
  {
    title: "Amount",
    dataIndex: "value",
    key:"value",
    sorter: {
      compare: (a, b) => {
        a = a.value;
        b = b.value;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  //   key: "Status",
  //   class: "text-center",
  //   sorter: {
  //     compare: (a, b) => {
  //       a = a.status;
  //       b = b.status;
  //       return a > b ? -1 : b > a ? 1 : 0;
  //     },
  //   },
  // },

  {
    title: "Action",
    key: "action",
    sorter: true,
    class: "text-end",
  },
];

export default {
  emits:['close-delete-modal','asset-edit-data'],
  props:{
    reloadData:{
      type: Boolean,
      required: false,
      default: () => false,
    },
    deleteAssetData:{
      type: Boolean,
      required: false,
      default: () => false,
    },
    searchFiter:{
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      disableButton:false,
      columns,
      asset_data:[],
      perpage: 1,
      pagination: pagination,
      addassetstatus: [
        {
          name:"Pending",
          class:"fa-regular fa-circle-dot text-warning"
        },
        {
          name:"Approved",
          class:"fa-regular fa-circle-dot text-success"
        },
        {
          name:"Deployed",
          class:"fa-regular fa-circle-dot text-info"
        }, {
          name:"Damaged",
          class:"fa-regular fa-circle-dot text-danger"
        }],
        deleteID:null,
        asset_upload_id:null,
        assign_emp:null,
        assign_asset_id:null,
        employees:[],
        clientError:[],
        errors:[],
        hasReadPermission:null,
        hasEditPermission:null,
        hasDeletePermission:null
    };
  },
  methods:{
    async onAssignAsset()
    {
        this.disableButton = true;
        this.clientError = [];

        if(this.assign_emp == '')
        {
          this.clientError.push('asset_user_id');
        }

        if(!this.clientError?.length)
        {
          var token = window.localStorage.getItem("token");
          axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          let formData = new FormData();
          formData.append('asset_user_id',this.assign_emp);

          await axios.post('/asset/assign-asset/'+this.assign_asset_id,formData).then(response=>{
            this.disableButton = false;
                notification.open({
                  message: response?.data?.message || 'Successful',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_SUCCESS_COLOR,
                  },
                });
                this.$refs.assign_asset_close.click();
                this.fetchData()
            }).catch(error=>{
              this.disableButton = false;
              if(error?.response){
                var response = (error?.response);
                  
                notification.open({
                  message: response.data.message,
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
              
                this.errors = response?.data?.error
                
              }else{
                
                notification.open({
                  message: 'Server Error',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });
              }
          });
          
        }
    },
    handleChangeEvent(target)
    {
      this.clientError = this.clientError.filter(item => item !== target);
    },
    async getEmployee(){

    var token = window.localStorage.getItem("token");

    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    await axios.get('/employees/all').then(response=>{

      this.employees = response?.data?.data ? response?.data?.data : []
    }).catch(error => {
      if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
          localStorage.clear();
          
          notification.open({
              message: 'Please Login',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });

          
          this.$router.push({name: 'login'}).catch(error => {}) 
      }
      
      if(error?.response){
      
        var response = (error.response);
          
        notification.open({
          message: response.data.message,
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
        
      }else{
        
        notification.open({
          message: 'Server Error During Fetching Overtime Records',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_WARNING_COLOR,
          },
        });
      }
    });

    },
    assignAsset(asset_id){
      this.assign_emp = '';
      this.assign_asset_id = asset_id;
    },
    async onAttachIncident()
    {
      this.disableButton = true;
      const formData = new FormData();
      const filesInput = this.$refs.attachments;
          for (let i = 0; i < filesInput.files.length; i++) {
          formData.append('documents[]', filesInput.files[i]);
      }

      const config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
      };

      var token = window.localStorage.getItem("token");
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      await axios.post('/asset/document-upload/'+this.asset_upload_id,formData,config).then(response => {

        notification.open({
          message: response?.data?.message || 'Successful',
          placement: "topRight",
          duration: process.env.VUE_APP_NOTIFICATION_DURATION,
          style: {
            background: process.env.VUE_APP_SUCCESS_COLOR,
          },
        });

        this.$refs.upload_asset_close.click();

        this.$router.push({path: '/assets-details/'+this.asset_upload_id}).catch(error => {});

        this.asset_upload_id = null;

      }).catch(error=>{
        if(error.response){
          var response = (error.response);
            
          notification.open({
            message: response.data.message,
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        
          this.errors = response?.data?.error
          
        }else{
          
          notification.open({
            message: 'Server Error',
            placement: "topRight",
            duration: process.env.VUE_APP_NOTIFICATION_DURATION,
            style: {
              background: process.env.VUE_APP_WARNING_COLOR,
            },
          });
        }
      });

      this.disableButton = false;
    },
    upload_images(asset_id = null)
    {
      this.asset_upload_id = asset_id;
      this.$refs.attachments.value = '';
    },
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.user.avatar = null;
      record.user.avatar = `assets/img/profiles/avatar-02.jpg`;
    },
    handleTableChange(pagesize){
      var params = {
        params: { per_page: pagesize.pageSize,page:pagesize.current }
      };
      this.fetchData(params,null);
    },
    updatePagination()
    {
      this.fetchData(null,null);
    },
    async fetchData(params = null,search = null){
        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: false
        });

        var param = null

        if(params != null)
        {
          param = params
        }
        else
        {
          param = {
            params: { per_page: this.pagination.pageSize }
          };
        }

        if(search != null)
        {
          param.params = {...param.params,...search}
        }

      var token = window.localStorage.getItem("token");
	
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      await axios.get('/asset',param).then(response => {
        loader.hide();

        this.asset_data = response?.data?.data?.data || [];

        this.pagination.total = response?.data?.data?.total;
        this.pagination.current = response?.data?.data?.current_page;
        this.pagination.pageSize = response?.data?.data?.per_page;

      }).catch(error => {
        loader.hide();
          if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
            localStorage.clear();
            
            notification.open({
                message: 'Please Login',
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });

            
            this.$router.push({name: 'login'}).catch(error => {}) 
          }
          
          if(error?.response){
          
            var response = (error.response);
              
            notification.open({
              message: response.data.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
            
          }else{
            
            notification.open({
              message: 'Server Error During Fetching Overtime Records',
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
          }
      })
    },
    async deleteAsset(assetID = null)
    {
      if(assetID)
      {
        var token = window.localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        await axios.delete('/asset/'+assetID,).then(response => {

          this.$emit('close-delete-modal');

          this.fetchData();

         

          notification.open({
              message: response?.data?.message,
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
              background: process.env.VUE_APP_SUCCESS_COLOR,
              },
          });

        }).catch(error => {
            if(error?.response?.status == 401 && response?.data?.message == 'Unauthenticated.'){
              localStorage.clear();
              
              notification.open({
                  message: 'Please Login',
                  placement: "topRight",
                  duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                  style: {
                    background: process.env.VUE_APP_WARNING_COLOR,
                  },
                });

              
              this.$router.push({name: 'login'}).catch(error => {}) 
            }
            
            if(error?.response){
            
              var response = (error.response);
                
              notification.open({
                message: response.data.message,
                placement: "topRight",
                duration: process.env.VUE_APP_NOTIFICATION_DURATION,
                style: {
                  background: process.env.VUE_APP_WARNING_COLOR,
                },
              });
              
            }
        })
      }
      else
      {
        notification.open({
              message: "Record not found!",
              placement: "topRight",
              duration: process.env.VUE_APP_NOTIFICATION_DURATION,
              style: {
                background: process.env.VUE_APP_WARNING_COLOR,
              },
            });
      }
    },
    get_warranty_date(purchase_date = null, warranty_end = null)
    {
      if(purchase_date && warranty_end){
        
          const date = new Date(purchase_date);

          if(date && Number.isInteger(warranty_end))
          {
            date.setMonth(date.getMonth() + warranty_end);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(date.getDate()).padStart(2, '0');

            return `${year}-${month}-${day}`;
          }
      }
      return '-';
    },
    get_image_path(images)
    {
      if(images && images.length)
      {
        const image = images.find(image => {
        const documentType = image?.document_type?.toLowerCase() || '';
            return documentType.includes('jpg') || documentType.includes('jpeg') || documentType.includes('png');
        });

        if(image?.file_path && image?.file_path != null)
        {
          return `${process.env.VUE_APP_IMAGE_BASE_URL}${image?.file_path}`
        }
        else
        {
          return null
        }
        
      }
      else{
        return null
      }
    },
  },
  created(){
    this.$store.dispatch('userPermissions', { module: 'asset', action: 'read' }).then(response =>{
      this.hasReadPermission=null;
      this.$nextTick(()=>{
        this.hasReadPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'asset', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'asset', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

    this.fetchData(null,null);
    this.getEmployee();
  },
  watch:{
    reloadData(newVal)
    {
      if(newVal){
        this.deleteID = null;
        this.fetchData(null,null)
      }
    },
    deleteAssetData(newVal)
    {
      if(newVal)
      {
        this.deleteAsset(this.deleteID)
      }
      
    },
    searchFiter(searchData)
    {
        this.fetchData(null,searchData);
    }
  }
};
</script>
<style scoped>
.asset-image-sm
{
  max-width: 40px;
  max-height: 40px;
}
</style>
