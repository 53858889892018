<template>
  <div class="col-lg-4 col-sm-6 col-md-4 col-xl-3">
    <div class="card">
      <div class="card-body card-fix-length">
        <div class="dropdown dropdown-action profile-action" v-if="hasEditPermission || hasDeletePermission">
          <a  
            href="javascript:void(0);"
            class="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ><i class="material-icons">more_vert</i></a
          >
          <div class="dropdown-menu dropdown-menu-right">
            <a v-if="hasEditPermission"
              class="dropdown-item"
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#edit_project"
			  @click="EditProject(project)"
              ><i class="fa-solid fa-pencil m-r-5"></i> Edit</a
            >
            <a v-if="hasDeletePermission"
              class="dropdown-item"
              href="javascript:void(0);"
              data-bs-toggle="modal"
              data-bs-target="#delete_project"
			  @click="DeleteProj(project.project_id)"
              ><i class="fa-regular fa-trash-can m-r-5"></i> Delete</a
            >
          </div>
        </div>
		<h4 class="project-title">
          <router-link :to="'/project-view/' + project.project_id" class="truncate-2-lines">{{
            project.project_name
          }}</router-link>
        </h4>
		
        <small class="block text-ellipsis m-b-15">
         
		 <span class="text-xs me-1">{{ getPendingTaskCount(project?.task_details) || 0 }}</span>
          <span class="text-muted">open tasks, </span>
          <span class="text-xs me-1">{{ getCompletedTaskCount(project?.task_details) || 0 }}</span>
          <span class="text-muted">tasks completed</span>
        </small>
        <p class="text-muted truncate-5-lines" v-html="project.description">
        </p>
        <div class="pro-deadline m-b-15">
          <div class="sub-title">Deadline:</div>
          <div class="text-muted">{{ project.end_date }}</div>
        </div>
        <div class="project-members m-b-15">
          <div>Project Leader :</div>
          <ul class="team-members">
			<li v-if="project.leaderdetails">
			  <a href="javascript:void(0);" data-bs-toggle="tooltip" :title="project.leaderdetails.first_name"
                >
				<img v-bind:src="getEmployeeAvatar(project.leaderdetails.avatar)" @error="handleImageError(project.leaderdetails)"></a>
            </li>
          </ul>
        </div>
        <div class="project-members m-b-15">
          <div>Team :</div>
          <ul class="team-members" v-if="project?.teamdetails?.members">
            <li v-for="teamMember in project.teamdetails.members.slice(0, 5)" :key="teamMember.id" class="dropdown avatar-dropdown">
              <a :href="'#'" data-bs-toggle="tooltip" :title="teamMember?.first_name">
              <img v-bind:src="getEmployeeAvatar(teamMember.avatar)" @error="handleImageError(teamMember)">
              </a>
            </li>
            <li class="dropdown avatar-dropdown" v-if="(project?.teamdetails?.members?.length - 5) > 0">
              <a
                href="javascript:void(0);"
                class="all-users dropdown-toggle"
                >{{(project.teamdetails.members.length - 5) > 0 ? '+'+(project.teamdetails.members.length - 5) : ''}}</a
              >
            </li>

			<!-- <li class="dropdown avatar-dropdown" v-if="project.teamdetails && project.teamdetails.members.length > 1">
              <a
                href="javascript:void(0);"
                class="all-users dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >+{{project.teamdetails.members.length-1}}</a
              >
              <div class="dropdown-menu dropdown-menu-right">
                <div class="avatar-group">
				  <a v-for="teamMember in project.teamdetails.members" :key="teamMember.id" class="avatar avatar-xs">
					<img v-bind:src="teamMember.avatar" @error="$event.target.src=teammemberImg" alt="User Image">
				  </a>
                </div>
              </div>
            </li> -->
          </ul>
        </div>
        <p class="m-b-5">
          Progress
          <!--<span class="text-success float-end">{{ project.progress }}%</span> -->
		  <span class="text-success float-end">15%</span>
        </p>
        <div class="progress progress-xs mb-0">
		<!--
          <div
            class="progress-bar bg-success"
            role="progressbar"
            data-bs-toggle="tooltip"
            :title="project.progress"
            :style="'width: ' + project.progress + '%'"
          ></div> -->
		  <div
            class="progress-bar bg-success"
            role="progressbar"
            data-bs-toggle="tooltip"
            :title="project.progress"
            :style="'width: 15%'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    project: Object,
	teammemberImg: {
      type: String,
      default: require("@/assets/img/profiles/avatar-10.jpg")
    }
  },
  data(){
    return {
      hasEditPermission:null,
      hasDeletePermission:null,
      defaultAvatar:'avatar-02.jpg',
    }
  },  
  methods: {
    getEmployeeAvatar(avatar){
      return `${process.env.VUE_APP_IMAGE_BASE_URL}${avatar}`
    },
    handleImageError(record) {
      record.avatar = null;
      record.avatar = `assets/img/profiles/${this.defaultAvatar}`;
    },
  getPendingTaskCount(tasks)
  {
    return tasks?.filter(task => task.task_status === 'Pending').length || 0;
  },
  getCompletedTaskCount(tasks)
  {
    return tasks?.filter(task => task.task_status === 'Completed').length || 0;
  },
	EditProject(project){
		
		var editobj = Object.assign({}, project);
	
		this.$emit("edit-project",editobj);
	},
	DeleteProj(id){
		
		this.$emit("delete-project",id);
	}
  },
  async created(){
    this.$store.dispatch('userPermissions', { module: 'project', action: 'write' }).then(response =>{
      this.hasEditPermission=null;
      this.$nextTick(()=>{
        this.hasEditPermission = response;
      })
    })

    this.$store.dispatch('userPermissions', { module: 'project', action: 'delete' }).then(response =>{
      this.hasDeletePermission=null;
      this.$nextTick(()=>{
        this.hasDeletePermission = response;
      })
    })

  }
};
</script>
<style>
  .truncate-5-lines {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    max-height: 100px; 
    line-height: 1.7em; 
  }
  .truncate-2-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    max-height: 50px;
  }
  .card-fix-length
  {
    max-height: 471px !important;
    min-height: 471px !important;
  }
</style>